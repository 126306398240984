import {Expose} from "class-transformer";

export default class ModelRates {
    @Expose({name: "buy"})
    private readonly _buy: number;
    @Expose({name: "sell"})
    private readonly _sell: number;

    constructor(buy: number, sell: number) {
        this._buy = buy;
        this._sell = sell;
    }

    public get buy() {
        return this._buy;
    }

    public get sell() {
        return this._sell;
    }
}
