import {Expose} from "class-transformer";

export default class ModelAccountProject {
    @Expose({name: 'name'})
    private readonly _name: string;
    @Expose({name: 'slug'})
    private readonly _slug: string;

    constructor(name: string, slug: string) {
        this._name = name;
        this._slug = slug;
    }

    public get name(): string {
        return this._name;
    }

    public get slug(): string {
        return this._slug;
    }
}
