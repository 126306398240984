import {f7} from "framework7-vue"
import humps from "lodash-humps-ts"
// @ts-ignore
import store from '@target/ts/store/store'
import i18n from "@/langs/i18n";
import ServerResponseError from "@/errors/ServerResponseError";

export default class ProjectApiService {
    // @ts-ignore
    private _url: String = import.meta.env.VITE_API_BASE_URL;
    private static instance: ProjectApiService | null = null;
    private _MAX_FETCH_TIMEOUT = 10000;

    public static getInstance() {
        if (ProjectApiService.instance === null) {
            ProjectApiService.instance = new ProjectApiService()
        }

        return ProjectApiService.instance
    }

    public setConfigDomain(domain: string) {
        this._url = domain;
    }

    public getConfigDomain() {
        return this._url;
    }

    public async get(path: string, body: GetBody = {},): Promise<any> {
        const uuid = Math.random() * 99999;
        const promise: Promise<Response> = new Promise(async (resolve, reject) => {
            const params = this.buildParams(body);
            // @ts-ignore
            const {t} = i18n.global;
            try {
                const response: Response = await fetch(`${this._url}${path}?${params}`, {
                    method: "GET",
                    // signal: AbortSignal.timeout(this._MAX_FETCH_TIMEOUT),
                    headers: this.makeHeaders(false, false),
                })
                let result = await response.json();

                if (!response.ok) {
                    if (response.status === 429) {
                        throw new ServerResponseError(t('network-errors.429'));
                    }
                    if (response.status === 401) {
                        throw new ServerResponseError("No auth");
                    }
                    throw new ServerResponseError(result.message);
                }

                resolve(humps(result));
            } catch (e: any) {
                switch (e.constructor) {
                    case ServerResponseError:
                        reject(new Error(e.message));
                        break;
                    default:
                        reject(new Error(t('network-errors.429')));
                }
            }
        });
        return promise;
    }

    public async post(path: string, body: FormData | any, isFile: boolean = false): Promise<Response> {
        const uuid = Math.random() * 99999;
        const promise: Promise<Response> = new Promise(async (resolve, reject) => {
            const {t} = i18n.global;
            let jsonFormat = true;
            if (body ! instanceof FormData) jsonFormat = false;

            try {
                const response: Response = await fetch(`${this._url}${path}`, {
                    method: "POST",
                    // signal: AbortSignal.timeout(this._MAX_FETCH_TIMEOUT),
                    headers: this.makeHeaders(isFile, jsonFormat),
                    body: jsonFormat ? JSON.stringify(body) : body
                });
                // this.removeFromActiveRequests(path)
                let result: any = await response.json();
                if (!response.ok) {
                    if (response.status === 429) {
                        throw new ServerResponseError(t('network-errors.429'));
                    }
                    throw new ServerResponseError(result.error);
                }
                if (response.status === 401) {
                    throw new ServerResponseError("No auth");
                }
                resolve(humps(result));
            } catch (e: any) {
                switch (e.constructor) {
                    case ServerResponseError:
                        reject(new Error(e.message));
                        break;
                    default:
                        reject(new Error(t('network-errors.429')));
                }
            }
        });
        return promise;
    }

    private makeHeaders(isFile: boolean = false, json: boolean = false) {
        const headers: Headers = {
            'Accept': 'application/json'
        }

        if (localStorage.getItem('token')) {
            headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
        }

        if (!isFile && json) headers['Content-Type'] = 'application/json'
        // else headers['Content-Type'] = 'application/json'

        return headers
    }

    private buildParams(data: Object) {
        const params = new URLSearchParams()

        Object.entries(data).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                value.forEach(value => params.append(`${key}[]`, value))
            } else {
                params.append(key, value)
            }
        });

        return params.toString()
    }
}

type PostBody = {
    [key: string]: any
}

type GetBody = {
    [key: string]: any
}

type Headers = {
    [key: string]: any
}
