import { Expose } from "class-transformer";

export default class ModelChatClient {
    @Expose({ name: "id" })
    private readonly _id: number;
    @Expose({ name: "name" })
    private readonly _name: string;
    @Expose({ name: "login" })
    private readonly _login: string;

    constructor(id: number, name: string, login: string) {
        this._id = id;
        this._name = name;
        this._login = login;
    }

    public get id() {
        return this._id;
    }

    public get name() {
        return this._name;
    }

    public get login() {
        return this._login;
    }
}
