import { Expose } from "class-transformer";

export default class ModelChatMessageAttachment {
    @Expose({ name: "id" })
    private readonly _id: number;
    @Expose({ name: "clientFilename"})
    private readonly _clientFilename: string;
    @Expose({ name: "filePath"})
    private readonly _filePath: string;
    @Expose({ name: "fileSize" })
    private readonly _fileSize: number;
    @Expose({ name: "messageId" })
    private readonly _messageId: number;
    @Expose({ name: "mimeType" })
    private readonly _mimeType: string;
    @Expose({ name: "thumbPath" })
    private readonly _thumbPath: string;
    @Expose({ name: "createdAt" })
    private readonly _createdAt: string;
    @Expose({ name: "updatedAt" })
    private readonly _updatedAt: string;

    constructor(
        id: number,
        clientFilename: string,
        filePath: string,
        fileSize: number,
        messageId: number,
        mimeType: string,
        thumbPath: string,
        createdAt: string,
        updatedAt: string
    ) {
        this._id = id;
        this._clientFilename = clientFilename;
        this._filePath = filePath;
        this._fileSize = fileSize;
        this._messageId = messageId;
        this._mimeType = mimeType;
        this._thumbPath = thumbPath;
        this._createdAt = createdAt;
        this._updatedAt = updatedAt;
    }

    public get id() {
        return this._id;
    }

    public get clientFilename() {
        return this._clientFilename;
    }

    public get filePath() {
        return this._filePath;
    }

    public get fileSize() {
        return this._fileSize;
    }

    public get messageId() {
        return this._messageId;
    }

    public get mimeType() {
        return this._mimeType;
    }

    public get thumbPath() {
        return this._thumbPath;
    }

    public get createdAt() {
        return this._createdAt;
    }

    public get updatedAt() {
        return this._updatedAt;
    }
}
