import { Expose, Type } from "class-transformer";
import ModelChatMessage from "@models/v2/chat/ModelChatMessage";
import ModelChatSocketChat from "@models/v2/chat/ModelChatSocketChat";

export default class ModelChatSocket {
    @Expose({ name: 'chat'})
    @Type(() => ModelChatSocketChat)
    private readonly _chat: ModelChatSocketChat;
    @Expose({ name: 'message'})
    @Type(() => ModelChatMessage)
    private readonly _message: ModelChatMessage;

    constructor(chat: ModelChatSocketChat, message: ModelChatMessage) {
        this._chat = chat;
        this._message = message;
    }

    public get chat() {
        return this._chat;
    }

    public get message() {
        return this._message;
    }
}
