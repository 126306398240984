import {Expose} from "class-transformer";

export default class ReleazioConfig {
    @Expose({name: 'channel'})
    private readonly _channel: string | null;
    @Expose({name: 'appVersionCode'})
    private readonly _appVersionCode: string | null;
    @Expose({name: 'appVersionName'})
    private readonly _appVersionName: string | null;
    @Expose({name: 'appDeeplink'})
    private readonly _appDeeplink: string | null;
    @Expose({name: 'channelPackageName'})
    private readonly _channelPackageName: string | null;
    @Expose({name: 'appUrl'})
    private readonly _appUrl: string | null;
    @Expose({name: 'postUrl'})
    private readonly _postUrl: string | null;
    @Expose({name: 'postsUrl'})
    private readonly _postsUrl: string | null;
    @Expose({name: 'updateType'})
    private readonly _updateType:  number | null;
    @Expose({name: 'updateMessage'})
    private readonly _updateMessage: string | null;

    public constructor(
        channel: string | null,
        appVersionCode: string | null,
        appVersionName: string | null,
        appDeeplink: string | null,
        channelPackageName: string | null,
        appUrl: string | null,
        postUrl: string | null,
        postsUrl: string | null,
        updateType: number | null,
        updateMessage: string | null
    ) {
        this._channel = channel;
        this._appVersionCode = appVersionCode;
        this._appVersionName = appVersionName;
        this._appDeeplink = appDeeplink;
        this._channelPackageName = channelPackageName;
        this._appUrl = appUrl;
        this._postUrl = postUrl;
        this._postsUrl = postsUrl;
        this._updateType = updateType;
        this._updateMessage = updateMessage;
    }

    get updateMessage(): string | null {
        return this._updateMessage;
    }
    get updateType(): number | null {
        return this._updateType;
    }
    get postsUrl(): string | null {
        return this._postsUrl;
    }
    get postUrl(): string | null {
        return this._postUrl;
    }
    get appUrl(): string | null {
        return this._appUrl;
    }
    get channelPackageName(): string | null {
        return this._channelPackageName;
    }
    get appDeeplink(): string | null {
        return this._appDeeplink;
    }
    get appVersionName(): string | null {
        return this._appVersionName;
    }
    get appVersionCode(): string | null {
        return this._appVersionCode;
    }
    get channel(): string | null {
        return this._channel;
    }
}