import MainApiService from "@/services/api-service/MainApiService";
import AgentsAmountRanges from "@models/errors/components/AgentsAmountRanges";
import {plainToInstance} from "class-transformer";
import {createPaymentPayload} from "@/services/operations/payment/PaymentService";
import PurchaseOperation from "@models/operations/PurchaseOperation";
import ServiceMainApi from "@/services/v2/service-main-api/ServiceMainApi";

export default class PurchaseRepository {
    async getAgentsAmountRanges(): Promise<AgentsAmountRanges> {
        // const response = await MainApiService.getInstance().get(`/api/mobile/client/payments/sumRange`);
        const { data } = await ServiceMainApi.of().get<{ data: any }>('/api/mobile/client/payments/sumRange');
        return plainToInstance(AgentsAmountRanges, data, {
            excludeExtraneousValues: true
        }) as unknown as AgentsAmountRanges;
    }

    async patchEmptyPayment(payload: createPaymentPayload): Promise<PurchaseOperation> {
        // TODO used in light target
        // const response = await MainApiService.getInstance().patch(`/api/mobile/client/payments/process`, payload);
        const response = await ServiceMainApi.of().patch<{ data: object }>(`/api/mobile/client/payments/process`, payload);
        return plainToInstance(PurchaseOperation, response.data, {
            excludeExtraneousValues: true,
            excludePrefixes: ['_']
        });
    }
}
