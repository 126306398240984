//TODO Все файлы в этой папке возможно deprecated
import store from '@target/core/store/store';
import {Ref, ref, computed, ToRef} from "vue";
import PaymentSystem from "@/entities/PaymentSystem";
import {autobind} from "@/decorators/autobind";
import ViewController from "@/interfaces/ViewController";
import {catchErrors} from "@/decorators/catch-errors";
import {f7, useStore} from 'framework7-vue';
// @ts-ignore
import AppController from '@target/components/App/ts/AppController';
import PaymentService, {createPaymentPayload} from "@/services/operations/payment/PaymentService";
import humps from "lodash-humps-ts";
import MainApiService from "@/services/api-service/MainApiService";
import Account from "@/entities/Account";
import DEFAULT_PAYMENT_SYSTEM from './DEFAULT_PAYMENT_SYSTEM.json';
import LogService from "@/services/log-service/LogService";
import PurchaseRepository from "@/repositories/purchase/PurchaseRepository";
import AgentsAmountRanges from "@models/errors/components/AgentsAmountRanges";

declare const window: Window & typeof globalThis & { Pusher: any, Echo: any }

export interface PaymentSystemSmartSelect extends PaymentSystem {
    selected: boolean;
}

export type DefaultAmount = {
    value: number;
    sign: string
}

export default class NewPaymentController implements ViewController {
    private static instance?: NewPaymentController;
    private _purchaseRepository: PurchaseRepository = new PurchaseRepository();
    private DEFAULT_AMOUNT_DOLLARS = 5;
    paymentSystems: Ref<Array<PaymentSystemSmartSelect>>;
    public agentsAmountRanges: Ref<AgentsAmountRanges | null> = ref(null);
    public isPaymentSystemsLoading: Ref<Boolean> = ref(false);
    private readonly _selectedPaymentSystem: Ref<any> = ref(null);
    amount: Ref<string>;
    public inputCurrency: Ref<string>;
    private readonly _currency: Ref<any>;
    defaultAmounts: Ref<DefaultAmount[]>;
    btnContinueLoading: Ref<boolean>;
    private _paymentService: PaymentService;

    static getInstance() {
        if (typeof this.instance === 'undefined') {
            this.instance = new NewPaymentController();
        }
        return this.instance;
    }

    private constructor() {
        AppController.getInstance().setPaymentRequestData(null);
        this._paymentService = AppController.getInstance().paymentService;
        this._currency = ref(store.getters['account'].value.currency);
        this.inputCurrency = ref(store.getters['account'].value.currency.name);

        this.paymentSystems = store.getters["paymentSystems"];
        this._selectedPaymentSystem = ref(this.initSelectedPaymentSystem());
        this._selectedPaymentSystem.value.currencies[0].dSums = this.calculateStartDefaultAmounts();
        this.amount = ref(`${this.calculateStartAmountValue()} ${this._currency.value.abbr}`);
        this.btnContinueLoading = ref(false);
    }

    public reset() {
        store.dispatch('resetPaymentSystems', null);
        this.paymentSystems.value = [];
        this._selectedPaymentSystem.value = null;
        this.agentsAmountRanges.value = null;
    }

    public async loadPaymentSystems() {
        try {
            this.isPaymentSystemsLoading.value = true;
            this.agentsAmountRanges.value = await this._purchaseRepository.getAgentsAmountRanges();
        } catch (e: any) {
            LogService.of().error("NewPaymentController@loadPaymentSystems", e.message);
        } finally {
            setTimeout(() => {
                this.isPaymentSystemsLoading.value = false;
            }, 314);
        }
    }

    private initSelectedPaymentSystem() {
        const defaultBank = humps(DEFAULT_PAYMENT_SYSTEM);
        if (!this.paymentSystems.value || this.paymentSystems.value.length === 0) return defaultBank;
        const bank = this.paymentSystems.value.find(el => el.sysName === "anybank");
        return typeof bank !== "undefined"
            ? bank
            : defaultBank;
    }

    public calculateStartAmountValue(): number {
        const account: ToRef<Account> = useStore("account");
        const {buy} = account.value.currency.rates;
        return Math.ceil(this.DEFAULT_AMOUNT_DOLLARS * buy);
    }

    private calculateStartDefaultAmounts(): DefaultAmount[] {
        const account: ToRef<Account> = useStore("account");
        const {dSums} = account.value.currency;
        return dSums;
    }

    public async fetchPSystems() {
        const response = await MainApiService.getInstance().get('/api/mobile/client/psystems');
        this.paymentSystems.value = response.data;
    }

    get currency(): Ref<any> {
        return this._currency;
    }

    get selectedPaymentSystem(): Ref<any> {
        return this._selectedPaymentSystem;
    }

    public getMinAmount(): number {
        return Math.ceil(this._selectedPaymentSystem.value.minAmount);
    }

    set setAmount(s: String) {
        // @ts-ignore
        this.amount.value = s;
    }

    private async configure() {
        this.setDefaultAmounts();
    }

    setDefaultAmounts() {
        const account = store.getters['account'].value;
        const defaultValues = account.currency.dSums;
        this.defaultAmounts.value = defaultValues.reduce((arr: DefaultAmount[], val: number) => {
            arr.push({
                value: val,
                sign: store.getters['currencySign'].value
            });
            return arr;
        }, []);
    }

    setDefaultAmountsManually(values: Array<number>, sign: string = 'USDT') {
        this.defaultAmounts.value = values.reduce((arr: DefaultAmount[], val: number) => {
            arr.push({
                value: val,
                sign
            });
            return arr;
        }, []);
    }

    getSelectedPaymentSystem(): PaymentSystem {
        return this.paymentSystems.value.find((el: PaymentSystemSmartSelect) => el.selected)!;
    }

    @autobind
    async beforeIn() {
        await store.dispatch('setPayment', undefined);
    }

    @autobind
    @catchErrors
    async updateNeedsData() {
        await store.dispatch('fetchAccount', null);
        await store.dispatch('fetchPaymentSystems', null);
        await store.dispatch('fetchCurrencies', null);
    }

    @autobind
    @catchErrors
    async updateMyPayments() {
        await store.dispatch('fetchMyPayments', {refresh: true});
    }

    public validateMinmax() {
        let amount = parseFloat(this.amount.value.replace(/\s/g, ''));
        if (amount < 10) return false;

        if (!this.agentsAmountRanges.value) return true;

        const {min, max} = this.agentsAmountRanges.value;
        if (min == null || max == null) return true;
        if (min == 0 || max == 0) return true;

        return !(amount < min || amount > max);
    }

    @autobind
    async next() {
        let amount = parseFloat(this.amount.value.replace(/\s/g, ''));
        // const currencySign = computed(() => store.getters['currencySign'].value);

        // try {
        // if (this.inputCurrency.value === store.getters['account'].value.currency.name) {
        //     if (amount < this.getMinAmount()) {
        //         f7.dialog.alert(`${i18n.global.t("g.errors.newPayment.minAmount")} ${this.getMinAmount()} ${currencySign.value}`, i18n.global.t("g.errors.newPayment.title"));
        //         return;
        //     }
        //     if (amount > this.getSelectedPaymentSystem().maxAmount) {
        //         f7.dialog.alert(`${i18n.global.t("g.errors.newPayment.maxAmount")} ${this.getSelectedPaymentSystem().maxAmount} ${currencySign.value}`, i18n.global.t("g.errors.newPayment.title"));
        //         return;
        //     }
        // } else {
        //     const rates = store.getters['rates'].value;
        //     const min = parseFloat((this.getMinAmount() / rates.buy).toFixed(2));
        //     const max = parseFloat((this.getSelectedPaymentSystem().maxAmount / rates.buy).toFixed(2));
        //     if (amount < min) {
        //         f7.dialog.alert(`${i18n.global.t("g.errors.newPayment.minAmount")} ${min} USDT`, i18n.global.t("g.errors.newPayment.title"));
        //         return;
        //     }
        //     if (amount > max) {
        //         f7.dialog.alert(`${i18n.global.t("g.errors.newPayment.maxAmount")} ${max} USDT`, i18n.global.t("g.errors.newPayment.title"));
        //         return;
        //     }
        //     amount = Math.ceil((amount * rates.buy).toFixed(2))
        // }

        const payload: createPaymentPayload = {
            amount: amount,
            psystem_id: null
        }
        await this._paymentService.createPayment(payload);
    }

    selectPSystem(psystem: any) {
        this._selectedPaymentSystem.value = psystem;
    }

    destructor() {
        this.reset();
        NewPaymentController.instance = undefined;
    }
}
