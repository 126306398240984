// @ts-ignore
import AppController from "@target/components/App/ts/AppController";
import ServiceCoreApi from "@/services/v2/service-core-api/ServiceCoreApi";
import FirebaseEventBuilder from "@/services/firebase/analytics/FirebaseEventBuilder";
import { FirebaseService } from "@/services/firebase/FirebaseService";
import { FirebaseEvents } from "@/services/firebase/analytics/FirebaseEvents";
import ServerResponseError from "@/errors/ServerResponseError";
import ServerAuthError from "@/errors/ServerAuthError";
import { PayloadServiceCoreApi, ServiceCoreApiResponse } from "@/services/v2/service-core-api/types";
import humps from "lodash-humps-ts";
import { ServiceCoreApiResponseError } from "@/services/v2/service-core-api/errors/ServiceCoreApiResponseError";

export default class ServiceMainApi {
    private static _instance: ServiceMainApi | null = null;
    private readonly _tokenNames = { requestName: 'X-Token', storageName: 'ltoken' }
    private readonly _cspNames = { requestName: 'X-Csp', storageName: 'csp' }
    private readonly _refreshTokenNames = { requestName: 'refresh_token', storageName: 'lrefreshToken' }
    private readonly _serviceApi: ServiceCoreApi;
    private _maxAttempts: number = 3;
    private _maxFetchTimeout = 10000;
    private readonly _baseHeaders: Record<string, string> = {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "App-Type": import.meta.env.VITE_APP_TYPE,
    };

    constructor() {
        this._serviceApi = new ServiceCoreApi(import.meta.env.VITE_API_URL);

        this._serviceApi.interceptors.request.use((config: RequestInit) => {
            config = {
                ...config,
                headers: {
                    ...this._baseHeaders,
                    ...config.headers
                },
                // signal: AbortSignal.timeout(this._maxFetchTimeout),
            }

            const xToken = this.getToken();

            if (xToken) {
                (config.headers as Record<string, string>)[this._tokenNames.requestName] = xToken;
            }

            return config;
        });

        this._serviceApi.interceptors.response.use(
            (response) => humps(response),
            async (error: any | ServiceCoreApiResponseError) => {
                const originalRequest = error.config;

                if (error.originalResponse.status === 401 && originalRequest && !originalRequest.config?._isRetry) {
                    originalRequest.config._isRetry = true;

                    try {
                        await this.refreshToken();
                        return this._serviceApi.request(originalRequest.url, originalRequest.config);
                    } catch (error: any) {
                        await AppController.getInstance().logout();
                    }
                }

                return Promise.reject(error);
            }
        );

    }

    public static of() {
        if (ServiceMainApi._instance === null) {
            ServiceMainApi._instance = new ServiceMainApi()
        }

        return ServiceMainApi._instance;
    }

    public async get<T>(
        path: string,
        payload: PayloadServiceCoreApi = {},
        config: RequestInit = {}
    ): Promise<T> {
        const { data } = await this.makeRequestWithRetry<T>(
            async (config) => this._serviceApi.get<T>(path, config, payload),
            this._maxAttempts,
            window.crypto.randomUUID(),
            config
        );

        return data;
    }

    public async post<T>(
        path: string,
        payload: BodyInit | null | FormData | PayloadServiceCoreApi = {},
        config: RequestInit = {}
    ): Promise<T> {
        const { data } = await this.makeRequestWithRetry<T>(
            async (config) => this._serviceApi.post<T>(path, config, payload),
            this._maxAttempts,
            window.crypto.randomUUID(),
            config
        );

        return data;
    }

    public async patch<T>(
        path: string,
        payload: PayloadServiceCoreApi = {},
        config: RequestInit = {}
    ): Promise<T> {
        const { data } = await this.makeRequestWithRetry<T>(
            async (config) => this._serviceApi.patch<T>(path, config, payload),
            this._maxAttempts,
            window.crypto.randomUUID(),
            config
        );

        return data;
    }

    public async delete<T>(
        path: string,
        payload: PayloadServiceCoreApi = {},
        config: RequestInit = {}
    ): Promise<T> {
        const { data } = await this.makeRequestWithRetry<T>(
            async (config) => this._serviceApi.delete<T>(path, config, payload),
            this._maxAttempts,
            window.crypto.randomUUID(),
            config
        );

        return data;
    }

    private getToken() {
        return localStorage.getItem(this._tokenNames.storageName);
    }

    private async makeRequestWithRetry<T>(
        request: (config: RequestInit) => Promise<ServiceCoreApiResponse<T>>,
        maxAttempts: number,
        requestUuid: string,
        config: RequestInit = {},
        retryRequestUuid: string | null = null,
        attempt: number = 1
    ): Promise<ServiceCoreApiResponse<T>> {
        try {
            const newConfig: RequestInit = {
                ...config,
                headers: {
                    ...config.headers,
                    "Request-Uuid": requestUuid,
                    ...(attempt > 1 && {
                        "Retry-for": retryRequestUuid || window.crypto.randomUUID(),
                        "Retry-Attempt": String(attempt),
                    })
                }
            };

            return await request(newConfig);
        } catch (error: any) {
            if (error instanceof ServerResponseError || error instanceof ServiceCoreApiResponseError) throw error;

            FirebaseService.of().analyticsEvent(
                FirebaseEvents.REQUEST_RETRY,
                FirebaseEventBuilder.of().build()
            );

            if (attempt === maxAttempts) throw error;

            return await this.makeRequestWithRetry(
                request,
                maxAttempts,
                window.crypto.randomUUID(),
                config,
                requestUuid,
                attempt + 1
            );
        }
    }

    private async refreshToken(): Promise<void> {
        const payload = { [this._refreshTokenNames.requestName]: localStorage.getItem(this._refreshTokenNames.storageName) || '' };

        const response = await fetch(this._serviceApi.url + '/api/mobile/auth/refresh', {
            method: "POST",
            body: JSON.stringify(payload),
            headers: {
                ...this._baseHeaders,
                [this._cspNames.requestName]: localStorage.getItem(this._cspNames.storageName) || ''
            }
        });

        if (!response.ok) {
            throw new ServerAuthError(response.statusText, response.status);
        }

        const data: { access_token: string, refresh_token: string } = await response.json();

        localStorage.setItem(this._tokenNames.storageName, data.access_token);
        localStorage.setItem(this._refreshTokenNames.storageName, data.refresh_token);
    }

    public setConfigDomain(value: string): void {
        this._serviceApi.url = value;
    }

    public getConfigDomain(): string | null {
        return this._serviceApi.url;
    }
}
