import ServiceMainApi from "@/services/v2/service-main-api/ServiceMainApi";
import { plainToInstance } from "class-transformer";
import { validateOrReject } from "class-validator";
import ModelAddressType from "@models/v2/addressType/ModelAddressType";
import i18n from "@/langs/i18n";

export default class RepositoryAddressTypes {
    public async fetchAddressTypes(): Promise<ModelAddressType[]> {
        const { data } = await ServiceMainApi.of().get<{ data: { [key: string]: any }[] }>('/api/mobile/client/requisites/address_types');

        return await this.prepareAddressTypesData(data);
    }

    private async prepareAddressTypesData(data: { [key: string]: any }[]): Promise<ModelAddressType[]> {
        const addressTypes: ModelAddressType[] = [];

        for (const addressTypeData of data) {
            const addressType = this.setNameAndIconBySlug(addressTypeData);

            const model: ModelAddressType = await this.plainToModel(addressType);

            addressTypes.push(model);
        }

        return addressTypes;
    }

    private setNameAndIconBySlug(object: { [key: string]: any }) {
        const addressType = object;

        switch (addressType.slug) {
            case "card":
                addressType.icon = 'blue-card-svg';
                // @ts-ignore
                addressType.name = i18n.global.t('requisite.address-types.card');
                break;
            case "phone":
                addressType.icon = 'sbp-svg';
                addressType.name = i18n.global.t('requisite.address-types.phone');
                break;
            default:
                addressType.name = i18n.global.t('requisite.address-types.unknown');
        }

        return addressType;
    }

    private async plainToModel(data: object): Promise<ModelAddressType> {
        const modelAddressType: ModelAddressType = plainToInstance(ModelAddressType, data, {
            excludeExtraneousValues: true,
            excludePrefixes: ['_']
        });

        await validateOrReject(modelAddressType);

        return modelAddressType;
    }
}
