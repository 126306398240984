import ServiceDIBuilder from "@/services/v2/service-DI/ServiceDIBuilder";

export default class ServiceDI {
    private _container: Map<String, Object>;

    public constructor() {
        this._container = new Map();
    }

    public get<T>(name: string): T | null {
        if (this._container.has(name)) return this._container.get(name) as T;
        return null;
    }

    public add(name: string, service: object) {
        this._container.set(name, service);
    }

    public static builder() {
        return new ServiceDIBuilder();
    }
}