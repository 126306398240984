import {f7} from "framework7-vue";

function copyClipboard(value: string): Promise<boolean> {
    try {
        const aux = document.createElement("textarea");
        aux.innerHTML = value;
        document.body.appendChild(aux);
        aux.select();
        document.execCommand("copy");
        document.body.removeChild(aux);

        return Promise.resolve(true);
    } catch (e) {
        return Promise.reject(false);
    }
}

function copyWithToast(value: string, t: any): void {
    copyClipboard(value)
    .then(() => {
        f7.toast.create({
            text: t('g.copied'),
            position: 'top',
            closeTimeout: 1000,
        }).open();
    })
        .catch(() => {
            f7.toast.create({
                text: t('g.failed-copy'),
                position: 'top',
                closeTimeout: 1000,
            }).open();
        });
}

export { copyClipboard, copyWithToast }
