import { Expose } from "class-transformer";

export default class ModelAddressType {
    @Expose({ name: "id" })
    private _id: number;
    @Expose({ name: "name" })
    private _name: string;
    @Expose({ name: "nameEn" })
    private _nameEn: string;
    @Expose({ name: "icon" })
    private _icon: string;
    @Expose({ name: "slug" })
    private _slug: string;

    constructor(id: number, name: string, nameEn: string, icon: string, slug: string) {
        this._id = id;
        this._name = name;
        this._nameEn = nameEn;
        this._icon = icon;
        this._slug = slug;
    }

    public get id() {
        return this._id;
    }

    public set id(value: number) {
        this._id = value;
    }

    public get name() {
        return this._name;
    }

    public set name(value: string) {
        this._name = value;
    }

    public get nameEn() {
        return this._nameEn;
    }

    public set nameEn(value: string) {
        this._nameEn = value;
    }

    public get icon() {
        return this._icon;
    }

    public set icon(value: string) {
        this._icon = value;
    }

    public get slug() {
        return this._slug;
    }

    public set slug(value: string) {
        this._slug = value;
    }
}
