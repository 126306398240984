import ServiceMainApi from "@/services/v2/service-main-api/ServiceMainApi";
import ModelRates from "@models/v2/rates/ModelRates";
import { plainToInstance } from "class-transformer";
import { validateOrReject } from "class-validator";

export default class RepositoryRates {
    public async fetchRates(): Promise<ModelRates> {
        const { data } = await ServiceMainApi.of().get<{ data: object }>('/api/mobile/client/rates');

        const rates = plainToInstance(ModelRates, data, {
            excludeExtraneousValues: true,
            excludePrefixes: ['_']
        });

        await validateOrReject(rates);

        return rates;
    }
}
