import { Expose, Type } from "class-transformer";
import ModelChatSocketChat from "@models/v2/chat/ModelChatSocketChat";
import ModelChatF7Message from "@models/v2/chat/f7/ModelChatF7Message";

export default class ModelChatF7Socket {
    @Expose({ name: 'chat'})
    @Type(() => ModelChatSocketChat)
    private readonly _chat: ModelChatSocketChat;
    @Expose({ name: 'message'})
    @Type(() => ModelChatF7Message)
    private readonly _message: ModelChatF7Message;

    constructor(chat: ModelChatSocketChat, message: ModelChatF7Message) {
        this._chat = chat;
        this._message = message;
    }

    public get chat() {
        return this._chat;
    }

    public get message() {
        return this._message;
    }
}
