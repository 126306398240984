import { Expose, Type } from "class-transformer";
import ModelChatAgent from "@models/v2/chat/ModelChatAgent";
import ModelChatClient from "@models/v2/chat/ModelChatClient";
import ModelChatF7Message from "@models/v2/chat/f7/ModelChatF7Message";

type ModelChatTheme = "sale" | "purchase";

export default class ModelChatF7 {
    @Expose({ name: 'agent' })
    @Type(() => ModelChatAgent)
    private readonly _agent: ModelChatAgent;
    @Expose({ name: 'client' })
    @Type(() => ModelChatClient)
    private readonly _client: ModelChatClient;
    @Expose({ name: "id" })
    private readonly _id: number;
    @Expose({ name: "messages" })
    @Type(() => ModelChatF7Message)
    private readonly _messages: ModelChatF7Message[];
    @Expose({ name: "name" })
    private readonly _name: string;
    @Expose({ name: "theme" })
    private readonly _theme: ModelChatTheme;
    @Expose({ name: "themeId" })
    private readonly _themeId: string;
    @Expose({ name: "type" })
    private readonly _type: string;
    @Expose({ name: "createdAt" })
    private readonly _createdAt: string;
    @Expose({ name: "updatedAt" })
    private readonly _updatedAt: string;

    constructor(
        agent: ModelChatAgent,
        client: ModelChatClient,
        id: number,
        messages: ModelChatF7Message[],
        name: string,
        theme: ModelChatTheme,
        themeId: string,
        type: string,
        createdAt: string,
        updatedAt: string,
    ) {
        this._agent = agent;
        this._client = client;
        this._id = id;
        this._messages = messages;
        this._name = name;
        this._theme = theme;
        this._themeId = themeId;
        this._type = type;
        this._createdAt = createdAt;
        this._updatedAt = updatedAt;
    }

    public get agent(): ModelChatAgent {
        return this._agent;
    }

    public get client(): ModelChatClient {
        return this._client;
    }

    public get id(): number {
        return this._id;
    }

    public get messages(): ModelChatF7Message[] {
        return this._messages;
    }

    public get name(): string {
        return this._name;
    }

    public get theme(): string {
        return this._theme;
    }

    public get themeId(): string {
        return this._themeId;
    }

    public get type(): string {
        return this._type;
    }

    public get createdAt(): string {
        return this._createdAt;
    }

    public get updatedAt(): string {
        return this._updatedAt;
    }
}
