import ServiceDI from "@/services/v2/service-DI/ServiceDI";

export default class ServiceDIBuilder {
    private readonly _ServiceDI: ServiceDI;

    public constructor() {
        this._ServiceDI = new ServiceDI();
    }

    public add(name: string, service: object) {
        this._ServiceDI.add(name, service);
        return this;
    }

    public addPack(list: { key: string, value: Object }[]) {
        list.map((el) => this._ServiceDI.add(el.key, el.value));
        return this;
    }

    public build() {
        return this._ServiceDI;
    }
}