import { ref, Ref } from "vue";
import ModelAddressType from "@models/v2/addressType/ModelAddressType";
import RepositoryAddressTypes from "@/repositories/v2/repository-address-types/RepositoryAddressTypes";

export class ServiceAddressTypes {
    private static _instance: ServiceAddressTypes | null = null;
    private readonly _repositoryAddressType: RepositoryAddressTypes;
    private readonly _addressTypes: Ref<ModelAddressType[]>;

    constructor() {
        this._addressTypes = ref([]);
        this._repositoryAddressType = new RepositoryAddressTypes();
    }

    public static of() {
        if (ServiceAddressTypes._instance === null) {
            ServiceAddressTypes._instance = new ServiceAddressTypes();
        }

        return ServiceAddressTypes._instance;
    }

    public get addressTypes(): Ref<ModelAddressType[]> {
        return this._addressTypes;
    }

    public async fetchAddressTypes() {
        this._addressTypes.value = await this._repositoryAddressType.fetchAddressTypes();
    }
}
