import ServiceMainApi from "@/services/v2/service-main-api/ServiceMainApi";
import ModelAccount from "@models/v2/account/ModelAccount";
import {plainToInstance} from "class-transformer";
import {validateOrReject} from "class-validator";

export default class RepositoryAccount {
    public async fetchAccount(): Promise<ModelAccount> {
        const {data} = await ServiceMainApi.of().get<{ data: object }>('/api/mobile/client');
        return this.plainToModel(data);
    }

    public async updateDeviceTokens(payload: object): Promise<void> {
        await ServiceMainApi.of().post<{ data: object }>('/api/mobile/client/device/push-tokens', payload);
    }

    public async patchAccount(payload: object): Promise<ModelAccount> {
        const {data} = await ServiceMainApi.of().patch<{ data: object }>('/api/mobile/client', payload);

        return this.plainToModel(data);
    }

    public async deleteAccount(): Promise<void> {
        await ServiceMainApi.of().delete('/api/mobile/client');
    }

    private async plainToModel(data: object): Promise<ModelAccount> {
        const account: ModelAccount = plainToInstance(ModelAccount, data, {
            excludeExtraneousValues: true,
            excludePrefixes: ['_']
        });

        await validateOrReject(account);

        return account;
    }
}
