<template>
    <div class="payment-check-images-upload" :class="`step-${step}`">
        <template v-if="step == 1">
            <div
                class="payment-check-images-upload_to-upload-content"
                :class="[{'has-images': checkImages.length > 0}]"
                @click="uploadFile"
            >
                <div class="payment-check-images-upload_body">
                    <div class="payment-check-images-upload_to-upload" v-if="canUploadNewCheckImage">
                        <i class="payment-check-images-upload_icon-upload icon"></i>
                    </div>
                    <template v-if="isEmptyCheckImages">
                        <div class="payment-check-images-upload_instruction">
                            <p class="payment-check-images-upload_instruction-title">
                                {{ $t("payment.check-images.to-upload.title") }}</p>
                            <p class="payment-check-images-upload_instruction-description">
                                {{ $t("payment.check-images.to-upload.description") }}</p>
                        </div>
                    </template>
                    <template v-else>
                        <div class="payment-check-images-upload_check-images">
                            <div
                                v-for="checkImage in checkImages"
                                :key="checkImage.localBlobUrl"
                                class="payment-check-images-upload_check-image"
                                @click.stop
                            >
                                <img :src="checkImage.localBlobUrl" alt="" v-if="!checkImage.isPDF">
                                <i class="icon-pdf icon" v-else></i>

                                <i @click.stop="deleteCheckImage(checkImage)" class="remove-check-image-icon icon"></i>
                            </div>
                        </div>
                    </template>
                </div>
                <f7-button
                    v-if="checkImages.length > 0"
                    class="theme-type"
                    preloader
                    @click.stop="uploadCheckImages"
                >
                    {{ $t("payment.check-images.to-upload.submit") }}
                </f7-button>
                <input accept="image/*,application/pdf" type="file" ref="inputImageFile"
                       class="payment-check-images-upload_input-file" @change="inputFileEventChange">
            </div>
        </template>
        <template v-else-if="step == 2">
            <f7-list inset class="list-dividers list-strong payment-personal-data">
                <f7-list-input
                    type="text"
                    :placeholder="$t('payment.personal-data-input')"
                    clear-button
                    v-model:value="paymentPersonalData"
                >
                </f7-list-input>
                <f7-button
                    class="theme-type"
                    preloader
                    @click.stop="uploadPersonalData"
                >
                    {{ $t("payment.check-images.to-upload.submit") }}
                </f7-button>
            </f7-list>
        </template>
        <template v-else-if="step == 3">
            <div class="payment-check-images-upload_uploaded-content">
                <p class="payment-check-images-upload_uploaded-content_title">
                    <i class="icon"></i>
                    {{ $t("payment.check-images.uploaded.title") }}
                </p>
                <p class="payment-check-images-upload_uploaded-content_description">
                    {{ $t("payment.check-images.uploaded.description") }}</p>
            </div>
        </template>
    </div>
</template>

<script setup lang="ts">
import {computed, Ref, ref} from "vue";
import {f7} from "framework7-vue";
import i18n from "@/langs/i18n";
import PaymentCheckImage from "@models/operations/payment/PaymentCheckImage";
import {useI18n} from "vue-i18n";

const emits = defineEmits(["imageWasUploaded", "uploadCheckImages", "uploadPersonalData"]);
const checkImages: Ref<PaymentCheckImage[]> = ref([]);
const isEmptyCheckImages = computed(() => checkImages.value.length === 0);
const canUploadNewCheckImage = computed(() => checkImages.value.length < 3);
const inputImageFile = ref(null);
const successUploaded = ref(false);
const step = ref(1);
const paymentPersonalData = ref("");
const {t} = useI18n({useScope: 'global'});

function uploadPersonalData() {
    emits("uploadPersonalData", paymentPersonalData.value, () => {
        step.value = 3
    }, doFailedUploadActions);
}
function doSuccessUploadActions() {
    step.value = 2;
    successUploaded.value = true;
}

function doFailedUploadActions() {
    checkImages.value = [];
    paymentPersonalData.value = "";

    f7.toast.create({
        text: t('g.errors.default'),
        position: 'top',
        closeTimeout: 1000,
    }).open();
}

function uploadCheckImages() {
    emits("uploadCheckImages", checkImages.value, doSuccessUploadActions, doFailedUploadActions);
}

function deleteCheckImage(checkImage: PaymentCheckImage) {
    checkImages.value = checkImages.value.filter((el) => el.localBlobUrl !== checkImage.localBlobUrl)
}

function uploadFile() {
    if (canUploadNewCheckImage.value) inputImageFile.value.click();
}

function inputFileEventChange(event: Event) {
    try {
        const file = (event.target as HTMLInputElement).files![0];
        if (file) {
            checkFileSizeIsValid(file);
            checkFileExtensionIsValid(file);
            const fileIsPDF = checkFileIsPDF(file);
            const checkImage = new PaymentCheckImage(file, URL.createObjectURL(file), fileIsPDF);
            checkImages.value.push(checkImage);
        }
    } catch (e: any) {
        //@ts-ignore
        f7.dialog.alert(e.message, i18n.global.t("g.errors.alert.default-title"));
    } finally {
        inputImageFile.value.value = "";
    }
}

function checkFileIsPDF(file: File) {
    return !!file.type.match('.*pdf.*');
}

function checkFileSizeIsValid(file: File): boolean {
    const maxSizeMB = file.size / 1024 / 1024;
    if (maxSizeMB > 10) throw new Error(i18n.global.t("g.errors.chat.max-size"));
}

function checkFileExtensionIsValid(file: File): boolean {
    const extension = file.type.split('/')[1];
    if (!['jpeg', 'jpg', 'png', 'pdf'].includes(extension)) {
        throw new Error(i18n.global.t("g.errors.chat.invalid-format"));
    }
}
</script>

<style lang="scss">
.payment-check-images-upload {
    box-sizing: border-box;
    margin: 16px;
    background-color: var(--grey7);
    border-radius: 16px;
    width: calc(100% - 32px);
    padding: 16px;
    display: flex;
    align-items: center;
    //height: calc(135px - 32px);
    height: calc(135px);
    cursor: pointer;

    .payment-personal-data {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 0;
        gap: 12px;

        .list {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }

    &_to-upload-content {
        display: flex;
        gap: 12px;
        flex: 1;
        padding: 19px 0;

        &.has-images {
            flex-direction: column;
            padding: 0;

            .payment-check-images-upload_body {
                justify-content: center;
            }

            .payment-check-images-upload_to-upload {
                margin-right: 16px;
            }
        }
    }

    &_uploaded-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 8px;
        justify-content: center;
        text-align: center;

        &_title {
            color: red;
            @include text(17px, 500, var(--green1), 24px);

            i {
                width: 24px;
                height: 24px;
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.72024 14.1771L18.6529 5.39588C19.1898 4.86804 20.0604 4.86804 20.5973 5.39588C21.1342 5.92371 21.1342 6.77951 20.5973 7.30734L9.72024 18L3.4027 11.7896C2.86577 11.2617 2.86577 10.4059 3.4027 9.8781C3.93964 9.35027 4.8102 9.35027 5.34714 9.8781L9.72024 14.1771Z' fill='%2332BF61'/%3E%3C/svg%3E%0A");
            }
        }

        &_description {
            @include text(12px, 400, var(--grey2), 14px);
        }
    }

    &_body {
        display: flex;
    }

    &_check-images {
        display: flex;
        gap: 16px;
    }

    .button {
        cursor: pointer;
        width: 100%;
        height: 33px;
        @include text(14px, 500, var(--white1), 16px);
    }

    .remove-check-image-icon {
        cursor: pointer;
        height: 20px;
        width: 20px;
        background-image: url("data:image/svg+xml,%3Csvg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.4998 1.66699C15.1022 1.66699 18.8332 5.39795 18.8332 10.0003C18.8332 14.6027 15.1022 18.3337 10.4998 18.3337C5.89746 18.3337 2.1665 14.6027 2.1665 10.0003C2.1665 5.39795 5.89746 1.66699 10.4998 1.66699Z' fill='white'/%3E%3Cpath d='M10.4998 1.66699C15.1022 1.66699 18.8332 5.39795 18.8332 10.0003C18.8332 14.6027 15.1022 18.3337 10.4998 18.3337C5.89746 18.3337 2.1665 14.6027 2.1665 10.0003C2.1665 5.39795 5.89746 1.66699 10.4998 1.66699ZM13.0683 6.10472L10.4998 8.67428L7.93286 6.10472C7.56674 5.73861 6.97315 5.73861 6.60703 6.10472C6.24092 6.47084 6.24092 7.06443 6.60703 7.43055L9.17484 10.0014L6.60703 12.5732C6.24092 12.9393 6.24092 13.5329 6.60703 13.8991C6.97315 14.2652 7.56674 14.2652 7.93286 13.8991L10.4998 11.3284L13.0683 13.8991C13.4344 14.2652 14.028 14.2652 14.3941 13.8991C14.7602 13.5329 14.7602 12.9393 14.3941 12.5732L11.8248 10.0014L14.3941 7.43055C14.7602 7.06443 14.7602 6.47084 14.3941 6.10472C14.028 5.73861 13.4344 5.73861 13.0683 6.10472Z' fill='%23818E95'/%3E%3C/svg%3E%0A");
    }

    &_check-image {
        position: relative;
        display: flex;
        gap: 16px;

        img {
            border-radius: 8px;
            height: 58px;
            width: 58px;
        }

        .icon-pdf {
            height: 58px;
            width: 58px;
            background-image: url("data:image/svg+xml,%3Csvg width='59' height='58' viewBox='0 0 59 58' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M19.2423 19.3074C22.2132 19.3074 24.6291 16.8915 24.6291 13.9224V0H47.9537C50.4846 0 52.542 2.0613 52.542 4.5901V53.4099C52.542 55.9387 50.4846 58 47.9537 58H11.0437C8.513 58 6.45557 55.9388 6.45557 53.4099V19.3074H19.2423ZM20.6911 0.724528L7.14483 15.1164H18.098C19.5281 15.1164 20.6911 13.9513 20.6911 12.5214V0.724528ZM19.6454 41.2985C21.0989 41.2985 22.2405 40.9478 23.0704 40.2543C23.9044 39.5607 24.3214 38.6293 24.3213 37.4682C24.3213 36.6812 24.1262 35.9799 23.7444 35.3603C23.3586 34.7407 22.8171 34.2615 22.1156 33.9263C21.4143 33.5913 20.6078 33.4275 19.6959 33.4275H14.9422V45.3393H17.814V41.2985H19.6454ZM34.5185 42.5687C34.9782 41.7036 35.2082 40.7296 35.2082 39.6385H35.2081V39.0852C35.2042 38.0097 34.9666 37.0316 34.4952 36.1627C34.0236 35.2899 33.3652 34.6197 32.5274 34.1404C31.6857 33.6612 30.7388 33.4274 29.6869 33.4274H25.8487V45.3392H29.7687C30.7974 45.3353 31.7325 45.0859 32.5703 44.5988C33.4079 44.1117 34.0586 43.4337 34.5185 42.5687ZM39.5569 35.6407H44.6535V33.4275H36.685V45.3393H39.5569V40.5933H44.1898V38.3839H39.5569V35.6407ZM19.7275 35.6411H17.8143V39.0779H19.6963C20.2535 39.0779 20.6783 38.9416 20.9706 38.6648C21.2627 38.3843 21.4071 37.9907 21.4071 37.4843C21.4071 36.9271 21.2551 36.4828 20.9552 36.1478C20.6548 35.8203 20.2457 35.6488 19.7275 35.6411ZM28.7212 35.6411H29.6875C30.5331 35.6411 31.1759 35.9373 31.6162 36.5178C32.0604 37.1022 32.2825 37.9711 32.2825 39.1206V39.6389C32.2825 40.7922 32.0604 41.6651 31.6162 42.2496C31.1758 42.8381 30.5447 43.1302 29.7187 43.1302H28.7212V35.6411Z' fill='%23ACB4BE'/%3E%3C/svg%3E%0A");
        }

        .remove-check-image-icon {
            position: absolute;
            top: -5px;
            right: -5px;
        }
    }

    &_input-file {
        display: none;
    }

    &_instruction {
        display: flex;
        flex-direction: column;
        gap: 4px;

        &-title {
            @include text(16px, 500, var(--blue1), 19px);
        }

        &-description {
            @include text(12px, 400, var(--grey2), 14px);
        }
    }

    & + .payment-requisites {
        margin-top: 0;
    }

    &_to-upload {
        cursor: pointer;
        margin-right: 24px;
        display: flex;
        align-items: center;
    }

    &_icon-upload {
        width: 58px;
        height: 58px;
        background-image: url("data:image/svg+xml,%3Csvg width='58' height='59' viewBox='0 0 58 59' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='1' width='57' height='57' rx='7.5' fill='white'/%3E%3Crect x='0.5' y='1' width='57' height='57' rx='7.5' stroke='%23007AFF' stroke-dasharray='4 4'/%3E%3Cpath d='M28.9846 17.4C28.3693 17.4 27.8706 17.8988 27.8706 18.5141V28.3706H18.0141C17.3988 28.3706 16.9 28.8693 16.9 29.4846C16.9 30.0999 17.3988 30.5987 18.0141 30.5987H27.8706V40.4859C27.8706 41.1012 28.3693 41.6 28.9846 41.6C29.5999 41.6 30.0987 41.1012 30.0987 40.4859V30.5987H39.9859C40.6012 30.5987 41.1 30.0999 41.1 29.4846C41.1 28.8693 40.6012 28.3706 39.9859 28.3706H30.0987V18.5141C30.0987 17.8988 29.5999 17.4 28.9846 17.4Z' fill='%23007AFF' stroke='%23007AFF' stroke-width='0.2'/%3E%3C/svg%3E%0A");

        &:hover {
            opacity: 0.7;
        }
    }
}
</style>