<template>
    <f7-messages class="messages-block">
        <template v-if="messagesByDay.length > 0">
            <template v-for="(day, index) in messagesByDay" :key="index">
                <f7-messages-title><b>{{ day.humanDate }}</b> {{ day.humanTime }}
                </f7-messages-title>
                <f7-message
                    v-for="(message, idx) in day.messages" :key="message.id"
                    :type="message.type"
                    :name="message.name"
                    :class="{'message_with-text': (message.message && message.message.length > 0)}"
                    :first="isFirstMessage(message, +index, +idx)"
                    :last="isLastMessage(message, +index, +idx)"
                    :tail="isTailMessage(message, +index, +idx)"
                >
                    <template #image v-if="message.file && message.file.thumbPath">
                        <ChatMessagePDF
                            v-if="checkIsPDF(message.file.mimeType)"
                            :file="message.file"
                        />
                        <img v-else @click="openImage(message.file.filePath)" :src="message.file.thumbPath">
                    </template>

                    <template #avatar v-if="message.type === 'received'">
                        <div :class="['avatar', message.senderType]"></div>
                    </template>

                    <template #text v-if="message.senderType === 'client'">
                        <span v-if="message.message && message.message.length > 0">{{ message.message }}</span>
                        <span class="read-status" v-if="message.type === 'sent'">
                            <i class="icon check-svg" :class="{'was-read': message.isRead}"></i>
                        </span>
                    </template>

                    <template
                        #text
                        v-else-if="message.senderType !== 'client' && message.message && message.message.length > 0"
                    >
                        <span v-if="message.message && message.message.length > 0">{{ message.message }}</span>
                    </template>
                </f7-message>
            </template>
        </template>
        <template v-else>
            <NoMessages/>
        </template>
    </f7-messages>
</template>

<script setup lang="ts">
import NoMessages from "@/views/chat/components/NoMessages.vue";
import {computed, ComputedRef} from "vue";
import ChatMessagePDF from "@components/chat/ChatMessagePDF.vue";
import dayjs from "dayjs";
import ModelChatF7Message from "@models/v2/chat/f7/ModelChatF7Message";
import ModelChatF7 from "@models/v2/chat/f7/ModelChatF7";

interface Props {
    chat: ModelChatF7;
}

interface Emits {
    (e: 'imageClick', value: string): void,
}

interface MessagesByDay {
    unix: number;
    humanDate: string;
    humanTime: string;
    createdAt: string;
    messages: ModelChatF7Message[];
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const messagesByDay: ComputedRef<MessagesByDay[]> = computed(() => {
        const groupedMessages: Record<string, any> = {};

        props.chat?.messages.forEach(message => {
            const createdAt = dayjs(message.createdAt);
            const dayKey = createdAt.format('YYYY-MM-DD');

            if (!groupedMessages[dayKey]) {
                groupedMessages[dayKey] = {
                    humanDate: createdAt.format('DD.MM.YYYY'),
                    humanTime: createdAt.format('HH:mm'),
                    messages: [],
                    createdAt: message.createdAt,
                    createdAtUnix: createdAt.unix()
                };
            }

            groupedMessages[dayKey].messages.push(message);
        });

        return Object.values(groupedMessages).map(day => {
            day.messages.sort((a: ModelChatF7Message, b: ModelChatF7Message) => a.createdAtUnix - b.createdAtUnix);
            return day;
        });
});

const checkIsPDF = (type: string) => {
    return !!type.match('.*pdf.*');
}

const isFirstMessage = (message: ModelChatF7Message, idx1: number, idx2: number) => {
    const previousMessage = messagesByDay.value[idx1].messages[idx2 - 1];
    return !previousMessage || previousMessage.type !== message.type || previousMessage.name !== message.name;
}

const isLastMessage = (message: ModelChatF7Message, idx1: number, idx2: number) => {
    const nextMessage = messagesByDay.value[idx1].messages[idx2 + 1];
    return !nextMessage || nextMessage.type !== message.type || nextMessage.name !== message.name;
}

const isTailMessage = (message: ModelChatF7Message, idx1: number, idx2: number) => {
    const nextMessage = messagesByDay.value[idx1].messages[idx2 + 1];
    return !nextMessage || nextMessage.type !== message.type || nextMessage.name !== message.name;
}

const openImage = (value: string) => {
    emits('imageClick', value);
}
</script>

<style lang="scss">
.message_with-text {
    .message-image {
        &:has(.chat-message-pdf) {
            margin-bottom: 6px !important;
        }
    }
}
</style>
