import {Expose, Type} from "class-transformer";
import ModelAccountSettingsBan from "./ModelAccountSettingsBan";

export default class ModelAccountSettings {
    @Expose({name: "ban"})
    @Type(() => ModelAccountSettingsBan)
    private readonly _ban: ModelAccountSettingsBan;
    @Expose({name: "chatEnabled"})
    private readonly _chatEnabled: boolean;
    @Expose({name: "countryCode"})
    private readonly _countryCode: string;
    @Expose({name: "homeUrl"})
    private readonly _homeUrl: string;
    @Expose({name: "homeUrlType"})
    private readonly _homeUrlType: string;
    @Expose({name: "isBanned"})
    private readonly _isBanned: boolean;
    @Expose({name: "localization"})
    private readonly _localization: string;

    constructor(
        ban: ModelAccountSettingsBan,
        chatEnabled: boolean,
        countryCode: string,
        homeUrl: string,
        homeUrlType: string,
        isBanned: boolean,
        localization: string
    ) {
        this._ban = ban;
        this._chatEnabled = chatEnabled;
        this._countryCode = countryCode;
        this._homeUrl = homeUrl;
        this._homeUrlType = homeUrlType;
        this._isBanned = isBanned;
        this._localization = localization;
    }

    public get ban(): ModelAccountSettingsBan {
        return this._ban;
    }

    public get chatEnabled(): boolean {
        return this._chatEnabled;
    }

    public get countryCode(): string {
        return this._countryCode;
    }

    public get homeUrl(): string {
        return this._homeUrl;
    }

    public get homeUrlType(): string {
        return this._homeUrlType;
    }

    public get isBanned(): boolean {
        return this._isBanned;
    }

    public get localization(): string {
        return this._localization;
    }
}
