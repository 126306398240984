<template>
    <f7-page
        ref="self"
        :page-content="false"
        class="requisite-page"
        :no-navbar="openIn == 'popup'"
        :class="{'without-navbar': openIn == 'popup'}"
        @page:beforein="handlePageBeforeIn"
        @page:beforeout="handlePageBeforeOut"
    >
        <f7-navbar :hidden="openIn === PageOpenIn.POPUP">
            <f7-nav-left>
                <a href="#" class="link icon-only" @click="back">
                    <i class="icon-back icon"></i>
                </a>
            </f7-nav-left>
            <f7-nav-title>
                {{ handleDrawingNavbarTitle() }}
            </f7-nav-title>
            <f7-nav-right>
                <f7-button
                    class="theme-type mini"
                    @click="handleSaveRequisite"
                >
                    {{ $t("requisite.btn-update") }}
                </f7-button>
            </f7-nav-right>
        </f7-navbar>
        <f7-page-content>
            <popup-navbar
                :title="handleDrawingNavbarTitle()"
                v-if="openIn === PageOpenIn.POPUP"
                @on-left-click="back"
            >
                <template #right>
                    <f7-button
                        class="theme-type mini"
                        @click="handleSaveRequisite"
                    >
                        {{ $t("requisite.btn-update") }}
                    </f7-button>
                </template>
            </popup-navbar>

            <template v-if="requisite">
                <requisite-page-target-types
                    :controller="requisiteTargetTypesController"
                    @on-select-type="handleSelectRequisiteTargetType"
                />
                <requisite-page-p2-p-requisite
                    v-if="requisiteTargetTypesController.getActive() === RequisitePsystemTargetType.P2P"
                    v-model:requisite="requisite"
                    ref="requisiteEl"
                    :loading="psystemPageLoading"
                    @toSelectPsystem="toRequisitePsystemPage"
                />
                <requisite-page-wallet-requisite
                    v-else
                    ref="requisiteEl"
                    v-model:requisite="requisite"
                    :loading="psystemPageLoading"
                    @toSelectPsystem="toRequisitePsystemPage"
                />
                <f7-block v-if="!requisite.isNew()" class="requisite-page_delete-requisite-block mt-6 mb-6">
                    <p @click="handleDeleteRequisite">{{ $t("requisite.btn-delete") }}</p>
                </f7-block>
            </template>
        </f7-page-content>
    </f7-page>
</template>

<script lang="ts" setup>
// @ts-ignore
import AppController from "@target/components/App/ts/AppController";
import RouterService from "@/services/RouterService";
import {useI18n} from "vue-i18n";
import RequisitePageController from "@/views/requisites-group/requisite/RequisitePageController";
import RequisitePageTargetTypes from "@/views/requisites-group/requisite/components/RequisitePageTargetTypes.vue";
import RequisiteTargetTypes from "@/views/requisites-group/requisite/types/RequisiteTargetTypes";
import {onMounted, reactive, ref} from "vue";
import {RequisitePsystemTargetType} from "@enums/RequisitePsystemTargetType";
import RequisiteTargetTypesController from "@/views/requisites-group/requisite/types/RequisiteTargetTypesController";
import RequisitePageP2PRequisite from "@/views/requisites-group/requisite/components/RequisitePageP2PRequisite.vue";
import RequisitePageWalletRequisite
    from "@/views/requisites-group/requisite/components/RequisitePageWalletRequisite.vue";
import {f7} from "framework7-vue";
import RequisitesPageController from "@/views/requisites-group/requisites/RequisitesPageController";
import Requisite from "@models/requisite/Requisite";
import {PageOpenIn} from "@enums/PageOpenIn";
import PopupNavbar from "@components/popup/popup-navbar/PopupNavbar.vue";
import Animations from "@/helpers/animations";
import ErrorsService from "@/services/errors-service/ErrorsService";
import {getDevice} from "framework7/shared/get-device";

const requisiteEl = ref(null);
const routerService: RouterService = AppController.getInstance().routerService;
const controller = RequisitePageController.of();
const { openIn } = controller;
const requisite = controller._requisite;
const {t} = useI18n({useScope: 'global'});
const self = ref(null);

const requisiteTargetTypesController: RequisiteTargetTypesController = {
    values: reactive([
        {
            type: RequisitePsystemTargetType.P2P,
            title: t("requisite.target-types.p2p.title"),
            text: t("requisite.target-types.p2p.text"),
            isActive: controller.DEFAULT_PSYSTEM_TARGET === RequisitePsystemTargetType.P2P,
            isDisabled: false
        },
        {
            type: RequisitePsystemTargetType.WALLET,
            title: t("requisite.target-types.wallet.title"),
            text: t("requisite.target-types.wallet.text"),
            isActive: controller.DEFAULT_PSYSTEM_TARGET === RequisitePsystemTargetType.WALLET,
            isDisabled: false
        }
    ]),
    setDisabledRequisite(requisite: Requisite) {
        if (requisite.id != -1) {
            this.values.map((el: RequisiteTargetTypes) => {
                el.isDisabled = el.type !== requisite.psystem.targetType;
            })
        }
    },
    setDisabledRequisites() {
        this.values.map((el: RequisiteTargetTypes) => {
            el.isDisabled = true;
        })
    },
    update: function (requisiteTargetType: RequisiteTargetTypes) {
        if (requisite.value!.id == -1) {
            if (requisiteTargetType.type === RequisitePsystemTargetType.P2P) {
                requisite.value = Requisite.build(
                    controller.getPsystemByTargetType(RequisitePsystemTargetType.P2P)!
                );
            } else {
                requisite.value = Requisite.build(
                    controller.getPsystemByTargetType(RequisitePsystemTargetType.WALLET)!
                );
            }
        }
        this.values.map((el: RequisiteTargetTypes) => {
            el.isActive = el.type === requisiteTargetType.type;
        })
    },
    getActive: function (): RequisitePsystemTargetType {
        return this.values.find((el: RequisiteTargetTypes) => el.isActive)!.type;
    },
    setActive: function (requisitePsystemTargetType: RequisitePsystemTargetType): void {
        this.values.map((el: RequisiteTargetTypes) => {
            el.isActive = el.type === requisitePsystemTargetType;
        })
    }
}
const psystemPageLoading = ref(false);
const requisitePageLoading = ref(false);

onMounted(() => {
    if (requisite.value !== null) {
        requisiteTargetTypesController.setActive(requisite.value!.psystem.targetType)
        requisiteTargetTypesController.setDisabledRequisite(requisite.value);
    }
});

function back() {
    closeKeyboardFocusInput(routerService.toBack);
}

async function closeKeyboardFocusInput(callback: Function) {
    if (getDevice().cordova) {
        // @ts-ignore
        self.value.$el.querySelectorAll('input, textarea').forEach(element => {
            element.blur();
        });
        setTimeout(async () => {
            await callback();
        }, 314);
        return false;
    }
    callback();
}
async function handleDeleteRequisite() {
    f7.dialog.create({
        title: t('requisite.remove-popup.title'),
        text: t('requisite.remove-popup.text'),
        buttons: [
            {
                text: t('requisite.remove-popup.cancel'),
            },
            {
                text: t('requisite.remove-popup.ok'),
                onClick: async () => {
                    try {
                        f7.preloader.show();
                        await controller.delete(requisite.value!);
                        await RequisitesPageController.of().fetchAndSetRequisites();
                        routerService.toBack();
                    } catch (e: any) {

                    } finally {
                        f7.preloader.hide();
                    }
                }
            }
        ]
    }).open();
}
async function toRequisitePsystemPage() {
    try {
        psystemPageLoading.value = true;
        await routerService.toRequisitePsystemPage(openIn)
    } catch (e: any) {
        f7.dialog.alert(t('g.errors.alert.default-text'), t('g.errors.alert.default-title'));
    } finally {
        setTimeout(() => {
            psystemPageLoading.value = false;
        }, 314);
    }
}
async function handleSaveRequisite() {
    if (!isValidRequisite()) return;

    try {
        f7.preloader.show();
        requisitePageLoading.value = true;

        if (requisite.value?.id != -1) await controller.save();
        else await controller.create();

        await RequisitesPageController.of().fetchAndSetRequisites();
        routerService.toBack();
    } catch (e: any) {
        ErrorsService.of().handle(e);
    } finally {
        f7.preloader.hide();
        requisitePageLoading.value = false;
    }
}

function isValidRequisite() {
    let valid = true;

    if (requisiteEl.value!.addressEl && requisiteEl.value!.addressEl.text.length === 0) {
        requisiteEl.value!.addressEl.error = true;
        requisiteEl.value!.addressEl.errorText = t("requisite.errors.required");
        setTimeout(() => {
            Animations.shakeAnimation(requisiteEl.value!.addressEl.errorEl);
        }, 0)
        valid = false;
    } else if (requisiteEl.value!.addressEl && requisiteEl.value!.addressEl.text.length < 6) {
        requisiteEl.value!.addressEl.error = true;
        requisiteEl.value!.addressEl.errorText = t("requisite.errors.address-min");
        setTimeout(() => {
            Animations.shakeAnimation(requisiteEl.value!.addressEl.errorEl);
        }, 0)
        valid = false;
    }
    if (requisiteEl.value!.nameEl && requisiteEl.value!.nameEl.text.length === 0) {
        requisiteEl.value!.nameEl.error = true;
        requisiteEl.value!.nameEl.errorText = t("requisite.errors.required");
        setTimeout(() => {
            Animations.shakeAnimation(requisiteEl.value!.nameEl.errorEl);
        }, 0)
        valid = false;
    }

    return valid;
}

function handlePageBeforeIn() {
}

function handlePageBeforeOut() {
}

function handleSavingRequisite() {

}

function handleSelectRequisiteTargetType(requisiteTargetType: RequisiteTargetTypes) {
    requisiteTargetTypesController.update(requisiteTargetType);
}

function handleDrawingNavbarTitle() {
    if (requisite.value?.id != -1) return t('requisite.page-title');
    else return t('requisite.title-new');
}

</script>

<style lang="scss">
.requisite-page {
    &__navbar {
        //--f7-navbar-inner-padding-right: 16px;
    }
    &.without-navbar {
        --f7-page-navbar-offset: 0px;
        --f7-navbar-height: 0px;
        --f7-page-content-extra-padding-top: 24px;

        .popup-navbar {
            padding-right: 8px;
        }

        .requisite-page_requisite-target-types {
            margin-top: 16px;
        }

        //.page-content {
        //    --f7-page-navbar-offset: 0;
        //}
    }

    .page-content {
        padding-bottom: calc(24px + var(--f7-page-toolbar-bottom-offset, 0px) + var(--f7-safe-area-bottom) + var(--f7-page-content-extra-padding-bottom, 0px));
        position: relative;

        &::-webkit-scrollbar {
            display: none;
        }

        .requisite-page_delete-requisite-block {
            display: flex;
            justify-content: center;
            p {
                cursor: pointer;
                @include text(16px, 500, var(--red1), 19.2px);
            }
        }

        .psystem-wrapper {
            margin-top: 24px;

            .title {
                margin: 0px 16px;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                text-transform: uppercase;
                color: #818E95;
            }

            .title + .psystem {
                margin-top: 8px;
            }

            .psystem {
                cursor: pointer;
                position: relative;
                height: 48px;
                display: flex;
                background-color: #FFFFFF;
                //width: calc(100% - 32px);
                align-items: center;
                padding: 0px 16px;

                &:after {
                    content: '';
                    position: absolute;
                    background-color: var(--f7-list-border-color);
                    display: block;
                    z-index: 15;
                    top: auto;
                    right: auto;
                    bottom: 0;
                    left: 0;
                    height: 1px;
                    width: 100%;
                    transform-origin: 50% 100%;
                    transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
                }

                &:before {
                    content: '';
                    position: absolute;
                    background-color: var(--f7-list-border-color);
                    display: block;
                    z-index: 15;
                    top: 0;
                    right: auto;
                    bottom: auto;
                    left: 0;
                    height: 1px;
                    width: 100%;
                    transform-origin: 50% 0%;
                    transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
                }

                .left-side {
                    display: flex;
                    align-items: center;
                    flex: 1;
                    gap: 8px;

                    img {
                        width: 24px;
                        height: 24px;
                    }

                    .name {
                        @include text(16px, 400, var(--brown1), 19.2px);

                        .address {
                            @include text(16px, 400, #818E95, 19.2px);
                        }
                    }
                }

                .svg-arrow-right {
                    width: 16px;
                    height: 16px;
                    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 7.99967C11.9922 7.73662 11.8903 7.51114 11.6787 7.30821L5.58276 1.58855C5.40255 1.4232 5.19099 1.33301 4.93242 1.33301C4.40744 1.33301 4 1.72384 4 2.22741C4 2.46792 4.10186 2.6934 4.28208 2.86627L9.7669 7.99967L4.28208 13.1331C4.10186 13.306 4 13.5239 4 13.7719C4 14.2755 4.40744 14.6663 4.93242 14.6663C5.18315 14.6663 5.40255 14.5761 5.58276 14.4108L11.6787 8.68363C11.8981 8.48821 12 8.26273 12 7.99967Z' fill='%23D5D7D8'/%3E%3C/svg%3E%0A");
                }

                .dimmy-requisite {
                    display: flex;
                    align-items: center;
                    flex: 1;
                    justify-content: space-between;

                    p {
                        @include text(16px, 400, #BFBFBF);
                    }

                    .after {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 8px;

                        .svg-arrow-right {
                            width: 16px;
                            height: 16px;
                            background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 7.99967C11.9922 7.73662 11.8903 7.51114 11.6787 7.30821L5.58276 1.58855C5.40255 1.4232 5.19099 1.33301 4.93242 1.33301C4.40744 1.33301 4 1.72384 4 2.22741C4 2.46792 4.10186 2.6934 4.28208 2.86627L9.7669 7.99967L4.28208 13.1331C4.10186 13.306 4 13.5239 4 13.7719C4 14.2755 4.40744 14.6663 4.93242 14.6663C5.18315 14.6663 5.40255 14.5761 5.58276 14.4108L11.6787 8.68363C11.8981 8.48821 12 8.26273 12 7.99967Z' fill='%23D5D7D8'/%3E%3C/svg%3E%0A");
                        }
                    }
                }
            }
        }

        .title-wrapper {
            margin: 24px 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left-side {
                display: flex;
                align-items: center;
                gap: 16px;

                .title {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 22px;
                    line-height: 120%;
                    letter-spacing: 0.337647px;
                    color: #000000;
                }

                .icon-back-svg {
                    cursor: pointer;
                    width: 32px;
                    height: 32px;
                    background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z' fill='white'/%3E%3Cpath d='M11.3262 16.1863C11.3337 16.4524 11.4314 16.6805 11.6343 16.8858L17.4818 22.6721C17.6546 22.8394 17.8576 22.9307 18.1056 22.9307C18.6092 22.9307 19 22.5353 19 22.0258C19 21.7825 18.9023 21.5544 18.7294 21.3795L13.4682 16.1863L18.7294 10.993C18.9023 10.8181 19 10.5976 19 10.3467C19 9.83723 18.6092 9.44184 18.1056 9.44184C17.8651 9.44184 17.6546 9.53308 17.4818 9.70036L11.6343 15.4943C11.4239 15.692 11.3262 15.9201 11.3262 16.1863Z' fill='%23818E95'/%3E%3C/svg%3E%0A");
                }
            }
        }

        .label-title {
            margin: 0px 16px 8px;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            text-transform: uppercase;
            color: #818E95;
        }

        .requisite-address-type {
            .input-popup {
                cursor: pointer;
                height: 44px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0px 16px;
                color: #423636;

                .inner-content {
                    display: flex;
                    gap: 8px;
                }

                .arrow-right {
                    width: 18px;
                    height: 18px;
                    background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.5 9C13.4912 8.70406 13.3766 8.45039 13.1386 8.2221L6.28061 1.78749C6.07786 1.60147 5.83986 1.5 5.54897 1.5C4.95837 1.5 4.5 1.93968 4.5 2.5062C4.5 2.77678 4.61459 3.03044 4.81734 3.22492L10.9878 9L4.81734 14.7751C4.61459 14.9696 4.5 15.2148 4.5 15.4938C4.5 16.0603 4.95837 16.5 5.54897 16.5C5.83105 16.5 6.07786 16.3985 6.28061 16.2125L13.1386 9.76945C13.3854 9.54961 13.5 9.29594 13.5 9Z' fill='%23D5D7D8'/%3E%3C/svg%3E%0A");
                }
            }
        }

        .list {
            .hint {
                @include text(12px, 400, #818E95, 14.4px);
                margin: 8px 16px 0px;
            }
        }
    }

    .bottom-block {
        box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.1);
        background-color: #FFFFFF;
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: 0px;
        padding: 16px 16px calc(var(--f7-safe-area-bottom) + 16px) 16px;
        z-index: 100;
    }
}
</style>
