<template>
    <div class="view-payment-create-agent-payment-error">
        <div class="vpcape_left-side">
            <i class="icon svg-warning"></i>
        </div>
        <div class="vpcape_right-side">
            <template v-if="amount && paymentError.code === AccountError.AgentsUnavailableForRangeAmount">
                <p>{{ $t("payment-create-agents.errors.10034-1") }} <span v-format-number="amount">{{
                        amount
                    }} {{ currency.abbr }}</span>
                    {{ $t("payment-create-agents.errors.10034-2") }}</p>
            </template>
            <template v-else-if="amount && paymentError.code === AccountError.AgentsUnavailableForAmount">
                <p>{{ $t(`payment-create-agents.errors.${AccountError.AgentsUnavailableForAmount}`) }}</p>
            </template>
        </div>
    </div>
</template>

<script setup lang="ts">
import {PropType, toRef} from "vue";
import LightPaymentPidResponseError from "@models/responses/light-payment-pid/LightPaymentPidResponseError";
import Agent from "@models/agents/Agent";
import Currency from "@/entities/Currency";
import {AccountError} from "@enums/ServerErrorCodes";

const props = defineProps({
    paymentError: {
        type: Object as PropType<LightPaymentPidResponseError>,
        required: true
    },
    agent: {
        type: Object as PropType<Agent | null>,
        required: true
    },
    amount: {
        type: Object as PropType<number | null>,
        required: true
    },
    currency: {
        type: Object as PropType<Currency>,
        required: true
    },
});
const paymentError = toRef(props, 'paymentError');
const agent = toRef(props, 'agent');
const amount = toRef(props, 'amount');
const currency = toRef(props, 'currency');

</script>

<style lang="scss">
.view-payment-create-agent-payment-error {
    background: rgba(230, 151, 0, 0.1);
    border-radius: 12px;
    display: flex;
    color: rgba(207, 117, 41, 1);
    gap: 12px;
    padding: 12px;
    margin: 16px;

    .vpcape {
        &_left-side {
            display: flex;
            align-items: center;
        }
        p {
            @include text(14px, 400, #CF7529, 16.8px);
        }
    }
}
</style>
