import { Expose } from "class-transformer";

export default class ModelChatAgent {
    @Expose({ name: "id" })
    private readonly _id: number;
    @Expose({ name: "name" })
    private readonly _name: string;
    @Expose({ name: "isOnline" })
    private readonly _isOnline: boolean;
    @Expose({ name: "sex" })
    private readonly _sex: string;

    constructor(id: number, name: string, isOnline: boolean, sex: string) {
        this._id = id;
        this._name = name;
        this._isOnline = isOnline;
        this._sex = sex;
    }

    public get id() {
        return this._id;
    }

    public get name() {
        return this._name;
    }

    public get isOnline() {
        return this._isOnline;
    }

    public get sex() {
        return this._sex;
    }
}
