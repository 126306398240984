<template>
    <f7-page class="tech-page" :page-content="false">
        <f7-navbar>
            <f7-nav-left>
                <a href="#" class="link icon-only" @click="back">
                    <i class="icon-back icon svg-filter-blue1"></i>
                </a>
            </f7-nav-left>
            <f7-nav-title>{{ $t('g.tech.page.title') }}</f7-nav-title>
            <f7-nav-right>
                <a href="#" class="link icon-only" @click="share">
                    <i class="icon i-share-svg"></i>
                </a>
            </f7-nav-right>
        </f7-navbar>
        <f7-page-content>
            <p class="list-title">{{ $t('g.tech.page.groups.network') }}</p>
            <f7-list class="list-outline list-strong list-dividers">
                <f7-list-item
                    v-if="techSettings.token"
                    class="list-item-token"
                    title="Token"
                    :after="techSettings.token.substring(0, 20).concat('...')"
                    @click="copy(techSettings.token)"
                />
                <f7-list-item
                    v-if="techSettings.rt"
                    class="list-item-token"
                    title="Refresh token"
                    :after="techSettings.rt.substring(0, 20).concat('...')"
                    @click="copy(techSettings.rt)"
                />
                <f7-list-item
                    v-if="techSettings.csp"
                    title="CSP"
                    :after="techSettings.csp"
                    @click="copy(techSettings.csp)"
                />
                <f7-list-item
                    v-if="techSettings.isFirebaseInitialized"
                    title="Firebase"
                    :after="techSettings.isFirebaseInitialized"
                    @click="copy(techSettings.isFirebaseInitialized)"
                />
                <f7-list-item
                    v-if="techSettings.kodmobiUrl"
                    title="KodMobi URL"
                    :after="techSettings.kodmobiUrl"
                    @click="copy(techSettings.kodmobiUrl)"
                />
                <f7-list-item
                    v-if="techSettings.projectUrl"
                    title="Project URL"
                    :after="techSettings.projectUrl"
                    @click="copy(techSettings.projectUrl)"
                />
                <f7-list-item
                    v-if="techSettings.mainUrl"
                    title="Main URL"
                    :after="techSettings.mainUrl"
                    @click="copy(techSettings.mainUrl)"
                />
                <f7-list-item
                    v-if="techSettings.releazio"
                    title="Releazio"
                    :after="techSettings.releazio"
                    @click="copy(techSettings.releazio)"
                />
                <f7-list-item
                    v-if="techSettings.fcmToken"
                    title="FCM token"
                    :after="techSettings.fcmToken.slice(0, 20).concat('...')"
                    @click="copy(techSettings.fcmToken)"
                />
            </f7-list>
            <p class="list-title">{{ $t('g.tech.page.groups.basic') }}</p>
            <f7-list class="list-outline list-strong list-dividers">
                <f7-list-item
                    v-if="techSettings.userAgent"
                    title="UserAgent"
                    :after="techSettings.userAgent.split(' ')[0]"
                    @click="copy(techSettings.userAgent)"
                />
                <f7-list-item
                    v-if="techSettings.version"
                    title="Version"
                    :after="techSettings.version"
                    @click="copy(techSettings.version)"
                />
            </f7-list>
            <p class="list-title">{{ $t('g.tech.page.groups.payment') }}</p>
            <f7-list class="list-outline list-strong list-dividers">
                <f7-list-item
                    v-if="techSettings.isCanSelectAgent"
                    :title="t('g.tech.page.select-agent')"
                    :after="techSettings.isCanSelectAgent"
                    @click="copy(techSettings.isCanSelectAgent)"
                />
            </f7-list>
        </f7-page-content>
    </f7-page>
</template>

<script lang="ts" setup>
import {useI18n} from "vue-i18n";
import {f7} from "framework7-vue";
import ViewTechController from "./ViewTechController";
import {copyWithToast} from "@/helpers/copy-clipboard";
import ErrorsService from "@/services/errors-service/ErrorsService";

const {t} = useI18n({useScope: 'global'});
const techSettings = ViewTechController.of().readSettings().getSettings();

function copy(value: string) {
    copyWithToast(value, t);
}

function back() {
    f7.view.main.router.back();
}

function share() {
    try {
        const addLine = (label: string, value: any) => (value ? `${label}: ${value}\n` : '');

        const networkSettings = `${t('g.tech.page.groups.network')}\n` +
            addLine('Token', techSettings.token) +
            addLine('Refresh token', techSettings.rt) +
            addLine('CSP', techSettings.csp) +
            addLine('Firebase', techSettings.isFirebaseInitialized) +
            addLine('KodMobi URL', techSettings.kodmobiUrl) +
            addLine('Project URL', techSettings.projectUrl) +
            addLine('Main URL', techSettings.mainUrl) +
            addLine('Releazio', techSettings.releazio);
            addLine('FCM token', techSettings.fcmToken);

        const basicSettings = `${t('g.tech.page.groups.basic')}\n` +
            addLine('UserAgent', techSettings.userAgent) +
            addLine('Version', techSettings.version);

        const paymentSettings = `${t('g.tech.page.groups.payment')}\n` +
            addLine('Выбор агента', techSettings.isCanSelectAgent)

        let shareData = {
            text: `${networkSettings}\n${basicSettings}\n${paymentSettings}`,
        }

        if (navigator.share) navigator.share(shareData);
        else copy(shareData.text);
    } catch (e) {
        ErrorsService.of().handle(e);
    }
}

</script>

<style lang="scss">
    @import "@/scss/views/settings-view";

    .tech-page {
        --f7-page-bg-color: #efeff4;
        background-color: var(--f7-page-bg-color);
    }
</style>
