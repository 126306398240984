import PaymentSystem from "@/entities/PaymentSystem";
import Requisite from "@models/requisite/Requisite";
import ViewController from "@/interfaces/ViewController";
import MainApiService from "@/services/api-service/MainApiService";
import { Ref, ref, ToRef } from "vue";
import AgentsAmountRanges from "@models/errors/components/AgentsAmountRanges";
import ServiceMainApi from "@/services/v2/service-main-api/ServiceMainApi";
import { ServiceAddressTypes } from "@/services/v2/data/service-address-types/ServiceAddressTypes";
import ModelAddressType from "@models/v2/addressType/ModelAddressType";
import { FirebaseService } from "@/services/firebase/FirebaseService";
import DocumentWithdrawal from "@/services/firebase/firestore/documents/DocumentWithdrawal";
import { DocumentTypes } from "@/services/firebase/firestore/documents/DocumentTypes";
import { RequisitePsystemTargetType } from "@enums/RequisitePsystemTargetType";

const RequisitePayloadDefault: RequisitePayload = {
    psystem: {
        "id": 6,
        "name": "Любой Банк",
        "sysName": "otherbank",
        "addressTypes": [1, 2],
        "imgPath": `${import.meta.env.VITE_API_URL}/storage/psystems/thumb/LPZTPpvXyYgVshvg7C7ZNJC4mZMJsgD9SbiF1j6c.png`
    },
    name: '',
    address: '',
    addressTypeId: 1,
    comment: '',
    isDefault: true,
    id: null
}
type RequisitePayload = {
    psystem: PaymentSystem | null,
    name: string,
    address: string,
    addressTypeId: number,
    comment: string,
    isDefault: boolean,
    id: number | null
}

export enum OpenIn {
    popup = 'popup',
    page = 'page'
}

export default class GlobalRequisitesController implements ViewController {
    private static instance?: GlobalRequisitesController;
    private _requisites: ToRef<Requisite[]> = ref([]);
    private _psystems: ToRef<PaymentSystem[]> = ref([]);
    private _addressTypes: ToRef<ModelAddressType[]> = ServiceAddressTypes.of().addressTypes;
    private _requisitePayload: ToRef<RequisitePayload> = ref({...RequisitePayloadDefault});
    private _openIn: OpenIn = OpenIn.page;
    private _activeRequisite: ToRef<Requisite | null> = ref(null);
    private _activeRequisiteAmountRange: Ref<AgentsAmountRanges | null> = ref(null)

    get openIn() {
        return this._openIn;
    }

    get psystems() {
        return this._psystems;
    }

    get activeRequisite() {
        return this._activeRequisite;
    }

    get activeRequisiteAmountRange() {
        return this._activeRequisiteAmountRange;
    }

    get requisites() {
        return this._requisites;
    }

    get addressTypes() {
        return this._addressTypes;
    }

    get requisitePayload() {
        return this._requisitePayload;
    }

    //TODO not used
    public async checkActiveRequisite() {
        const defaultRequisite = this._requisites.value.find(el => el.isDefault);
        if (defaultRequisite) {
            this._activeRequisite.value = defaultRequisite;
            await this.fetchActiveRequsiteAmountRange(this._activeRequisite.value.id);
        }
    }

    public selectActiveRequisite(requisite: Requisite) {
        this._activeRequisite.value = requisite;
    }

    //TODO not used
    public setOpenIn(value: OpenIn) {
        this._openIn = value;
    }

    //TODO not used
    public setRequisitePayload(requisite: Requisite) {
        this._requisitePayload.value = {
            name: requisite.name,
            address: requisite.address,
            addressTypeId: requisite.addressTypeId,
            comment: requisite.comment,
            isDefault: requisite.isDefault,
            psystem: requisite.psystem,
            id: requisite.id
        }
    }

    //TODO not used
    public setRequisitePayloadIsDefault(value: boolean) {
        this._requisitePayload.value.isDefault = value;
    }

    //TODO not used
    public setCPPsystem(psystem: PaymentSystem) {
        this._requisitePayload.value.psystem = psystem;
    }

    static getInstance() {
        if (typeof this.instance === 'undefined') this.instance = new GlobalRequisitesController();
        return this.instance;
    }

    //TODO used in unused methods
    public async fetchPSystems() {
        // const response = await MainApiService.getInstance().get('/api/mobile/client/ps/list');
        const response = await ServiceMainApi.of().get<{ data: PaymentSystem[] }>('/api/mobile/client/ps/list');
        this._psystems.value = response.data;
    }

    public async fetchActiveRequsiteAmountRange(requisiteId: number) {
        this._activeRequisiteAmountRange.value = await MainApiService
            .getInstance()
            .get('/api/mobile/client/sales/amount_range', {requisite_id: requisiteId});
    }

    public async fetchRequisiteMinMax(requisite: Requisite) {
        //TODO вынести в сервис + репозиторий + модель
        const response = await ServiceMainApi.of().get<{ data: any }>("/api/mobile/client/withdrawals/amount_range", {requisite_id: requisite.id});

        this._activeRequisiteAmountRange.value = this.calculateMinMaxAmountRangesByFirebase(response.data) as AgentsAmountRanges;

        return this._activeRequisiteAmountRange.value;
    }

    //TODO В будущем добавить типизацию(any), когда появятся сервисы и репозитории
    private calculateMinMaxAmountRangesByFirebase(amountRanges: any) {
        const doc = FirebaseService.of().firestoreService?.getDocument<DocumentWithdrawal>(DocumentTypes.WITHDRAWAL);
        if (!doc || !this._activeRequisite) return amountRanges;

        const isWallet = this._activeRequisite.value?.psystem.targetType === RequisitePsystemTargetType.WALLET;
        const minMaxDocAmountRangeByType = isWallet ? doc.minWalletAmount : doc.minP2PAmount;

        if (minMaxDocAmountRangeByType === null) return amountRanges;

        return {
            ...amountRanges,
            min: Math.max(amountRanges.min, minMaxDocAmountRangeByType),
        }
    }

    public async fetchAddressTypes() {
        await ServiceAddressTypes.of().fetchAddressTypes();
    }

    //TODO not used
    public async createRequisite() {
        const {name, address, isDefault, comment, psystem} = this._requisitePayload.value;

        // let addressTypeId = 2;
        // if (psystem.addressTypes.includes(1) && psystem.addressTypes.includes(2)) {
        //     if (address.length >= 16) {
        //         addressTypeId = 1;
        //     }
        // } else if (psystem.addressTypes.includes(1)) {
        //     addressTypeId = 1;
        // } else {
        //     addressTypeId = 2;
        // }

        let addressTypeId = 1;
        if (address.length < 16) {
            addressTypeId = 2;
        }

        const result = await MainApiService.getInstance().post('/api/mobile/client/requisites/create', {
            psystem_id: psystem!.id,
            name: name,
            address: address,
            address_type_id: addressTypeId,
            comment: comment,
            is_default: true
        });
        await this.fetchAllRequisites();
        return result;
    }

    //TODO not used
    public async updateRequisite() {
        const {name, address, isDefault, comment, psystem, id} = this._requisitePayload.value;

        let addressTypeId = 1;
        if (address.length < 16) {
            addressTypeId = 2;
        }

        await MainApiService.getInstance().patch(`/api/mobile/client/requisites/${id}/update`, {
            psystem_id: psystem!.id,
            name: name,
            address: address,
            address_type_id: addressTypeId,
            comment: comment,
            is_default: true
        });
        await this.fetchAllRequisites();
    }

    public async fetchAllRequisites() {
        const response = await MainApiService.getInstance().get('/api/mobile/client/requisites');
        this._requisites.value = response.data;
    }

    //TODO not used
    public async toDraft(requisiteId: number) {
        await MainApiService.getInstance().patch(`/api/mobile/client/requisites/${requisiteId}/draft`);
        await this.fetchAllRequisites();
        if (this._activeRequisite.value && this._activeRequisite.value.id === requisiteId) {
            this._activeRequisite.value = null;
            this._activeRequisiteAmountRange.value = null;
        }
        if (this._requisites.value.length > 0) {
            this._activeRequisite.value = this._requisites.value[0];
            await this.fetchActiveRequsiteAmountRange(this._activeRequisite.value?.id);
        }
    }

    public initRequisitePayload() {
        this._requisitePayload.value = {...RequisitePayloadDefault};
    }

    destructor(): void {
        this._requisites.value = [];
    }
}

// Нет реквизитов - не работает
// Нет суммы на балансе
// Дроби в мин-макс
//
