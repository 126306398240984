import { Expose, Transform, Type } from "class-transformer";
import ModelChatMessageAttachment from "@models/v2/chat/ModelChatMessageAttachment";

export default class ModelChatF7Message {
    @Expose({ name: "id" })
    private readonly _id: number;
    @Expose({ name: "chatId" })
    private readonly _chatId: number;
    @Expose({ name: "message" })
    @Transform(({ value }) => filterMessageText(value))
    private readonly _message: string;
    @Expose({ name: "isRead" })
    private _isRead: boolean;
    @Expose({ name: "ln" })
    private readonly _ln: number;
    @Expose({ name: "senderName" })
    private readonly _senderName: string;
    @Expose({ name: "name" })
    private _name: string;
    @Expose({ name: 'senderType' })
    private readonly _senderType: string;
    @Expose({ name: "type" })
    private _type: string;
    @Expose({ name: 'waitFiles' })
    private readonly _waitFiles: number;
    @Expose({ name: "attachments"})
    @Type(() => ModelChatMessageAttachment)
    private readonly _attachments: ModelChatMessageAttachment[];
    @Expose({ name: "createdAt" })
    private readonly _createdAt: string;
    @Expose({ name: "createdAtUnix" })
    private _createdAtUnix: number;
    @Expose({ name: "updatedAt" })
    private readonly _updatedAt: string;
    @Expose({ name: "updatedAtUnix" })
    private _updatedAtUnix: number;

    constructor(
        id: number,
        chatId: number,
        message: string,
        isRead: boolean,
        ln: number,
        name: string,
        senderName: string,
        senderType: string,
        type: string,
        waitFiles: number,
        attachments: ModelChatMessageAttachment[],
        createdAt: string,
        createdAtUnix: number,
        updatedAt: string,
        updatedAtUnix: number
    ) {
        this._id = id;
        this._chatId = chatId;
        this._message = message;
        this._isRead = isRead;
        this._ln = ln;
        this._name = name;
        this._senderName = senderName;
        this._senderType = senderType;
        this._type = type;
        this._waitFiles = waitFiles;
        this._attachments = attachments;
        this._createdAt = createdAt;
        this._createdAtUnix = createdAtUnix;
        this._updatedAt = updatedAt;
        this._updatedAtUnix = updatedAtUnix;
    }

    public get id(): number {
        return this._id;
    }

    public get chatId(): number {
        return this._chatId;
    }

    public get message() {
        return this._message;
    }

    public get isRead() {
        return this._isRead;
    }

    public set isRead(value: boolean) {
        this._isRead = value;
    }

    public get ln() {
        return this._ln;
    }

    public get name() {
        return this._name;
    }

    public set name(value: string) {
        this._name = value;
    }

    public get senderName() {
        return this._senderName;
    }

    public get senderType() {
        return this._senderType;
    }

    public get type() {
        return this._type;
    }

    public set type(value: string) {
        this._type = value;
    }

    public get waitFiles() {
        return this._waitFiles;
    }

    public get attachments() {
        return this._attachments;
    }

    public get file(): ModelChatMessageAttachment | null {
        if (this._attachments.length === 0) return null;

        return this._attachments[0];
    }

    public get createdAt() {
        return this._createdAt;
    }

    public get createdAtUnix() {
        return this._createdAtUnix;
    }

    public set createdAtUnix(value: number) {
        this._createdAtUnix = value;
    }

    public get updatedAt() {
        return this._updatedAt;
    }

    public get updatedAtUnix() {
        return this._updatedAtUnix
    }

    public set updatedAtUnix(value: number) {
        this._updatedAtUnix = value;
    }
}

const filterMessageText = (text: string): string => {
    const wordsForExclusion: string[] = ["[attachments]"];
    wordsForExclusion.map(word => {
        text = text.replaceAll(word, '')
    });
    return text;
}
