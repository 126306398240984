<template>
    <f7-sheet
        class="ban-sheet"
        style="height:auto;"
        swipe-to-close
        backdrop
        ref="self"
    >
        <span class="bdg"></span>
        <div class="content">
            <SlothBlock
                :title="$t('accountBanned.title')"
                :subtitle="$t('accountBanned.text')"
                :type="SLOTH_TYPE.SLEEP"
            ></SlothBlock>
            <f7-list class="list-details" dividers-ios strong-ios outline-ios>
                <f7-list-item :title="$t('accountBanned.details.date')"
                              :after="dayjs(account.settings.ban.unbanAt).format('YYYY-MM-DD HH:mm')"></f7-list-item>
                <li class="comment-item item-content item-input" v-if="account.settings.ban.comment">
                    <div class="item-inner">
                        <div class="title">
                            {{ $t("accountBanned.details.comment") }}
                        </div>
                        <div class="comment">
                            <p v-html="(account.settings.ban.comment)"></p>
                        </div>
                    </div>
                </li>
            </f7-list>
            <p class="hint">{{ $t('accountBanned.details.hint') }}</p>
            <a href="#" @click="openAlertToTelegram" class="support">{{ $t('accountBanned.details.support') }}</a>
        </div>
    </f7-sheet>
</template>

<script lang="ts" setup>
import SlothBlock from "@/components/messages/SlothBlock.vue";
import {SLOTH_TYPE} from "@/entities/enums/SlothType";
import dayjs from "dayjs";
import {f7} from "framework7-vue";
import {getDevice} from "framework7/shared/get-device";
import {useI18n} from "vue-i18n";
import ModelAccount from "@models/v2/account/ModelAccount";

interface Props {
    account: ModelAccount;
}

defineProps<Props>();

const {t} = useI18n({useScope: 'global'});

function openAlertToTelegram() {
    f7.dialog.create({
        title: t("g.alerts.to-telegram.title"),
        text: t("g.alerts.to-telegram.text"),
        cssClass: "simple",
        buttons: [
            {
                text: t("g.no")
            },
            {
                text: t("g.yes"),
                onClick: async () => {
                    try {
                        const url = "https://t.me/BroMoney_robot";
                        if (getDevice().ios) {
                            // @ts-ignore
                            window.webkit.messageHandlers.iosListener.postMessage(JSON.stringify({
                                "deeplink": "https://t.me/BroMoney_robot"
                            }));
                        } else if (getDevice().android) {
                            // @ts-ignore
                            window.webInterface.onMessage(JSON.stringify({
                                "deeplink": "https://t.me/BroMoney_robot"
                            }));
                        } else {
                            window.open(url, "_target");
                        }
                    } catch (e) {
                        // if (getDevice().android) {
                        //     window.open('https://t.me/BroMoney_robot', "_target");
                        // }
                        window.open('https://t.me/BroMoney_robot', "_target");
                    }
                }
            }
        ]
    }).open();
};
</script>

<style lang="scss">
.ban-sheet {
    background-color: #EFEFF4;
    border-top-left-radius: 16px !important;
    border-top-right-radius: 16px !important;
    height: calc(var(--f7-safe-area-bottom) + 590px) !important;

    .bdg {
        width: 36px;
        height: 4px;
        position: absolute;
        top: 6px;
        z-index: 9999;
        background: #818E95;
        border-radius: 4px;
        left: 50%;
        transform: translateX(-50%);
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 56px 0px calc(var(--f7-safe-area-bottom) + 56px);
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;

        .list-details {
            width: 100%;

            .title, .item-title {
                font-style: normal;
                font-weight: 400;
                // font-size: 13px;
                font-size: 17px;
                line-height: 22px;
                letter-spacing: -0.41px;
                color: #717B81;
            }

            .item-after {
                font-size: 17px;
                font-weight: 500;
                color: #423636;
            }

            .comment-item {
                .item-inner {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    .comment {
                        position: relative;
                        width: 100%;
                        p {
                            word-wrap: break-word;
                            font-size: 15px;
                            color: #423636;
                            line-height: 19.2px;
                            font-weight: 500;
                            text-align: left;
                        }
                        .corner {
                            position: absolute;
                            left: -5px;
                            top: 0;
                            width: 17px;
                            height: 21px;
                            background-image: url("data:image/svg+xml,%3Csvg width='17' height='21' viewBox='0 0 17 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.112661 0.137665C5.31266 -0.662334 10.446 2.20104 12.1127 4.03437C10.3948 8.13084 21.0006 18.0804 14.0006 18.0808C12.382 18.0808 10.9995 22.3209 5.11266 19.1377C5.09145 17.8508 5.11266 13.3965 5.11266 12.6381C5.11266 2.13806 -0.887339 0.740955 0.112661 0.137665Z' fill='%23EFEFF4'/%3E%3C/svg%3E%0A");
                        }
                    }
                }
            }
        }
        .hint {
            @include text(16px, 400, #ACB4BE);
            text-align: center;
            padding: 0 16px;
        }
        .support {
            @include text(16px, 500, #007AFF);
            text-align: center;
            padding: 0 16px;
            margin-top: 20px;
        }

    }
}
</style>
