import MainApiService from "@/services/api-service/MainApiService";
import LightPaymentPidResponse from "@models/responses/light-payment-pid/LightPaymentPidResponse";
import {plainToInstance} from "class-transformer";
import ServiceMainApi from "@/services/v2/service-main-api/ServiceMainApi";

export default class LightAppService {
    async fetchDataWithPid(pid: string): Promise<LightPaymentPidResponse> {
        // const response: object = await MainApiService.getInstance().post(`/api/mobile/init/paylink`, {
        //     pid
        // });
        //TODO вынести на уровень repository, как fetchAccountByPid
        const response = await ServiceMainApi.of().post<{ data: object }>(`/api/mobile/init/paylink`, {
            pid
        });
        return plainToInstance(LightPaymentPidResponse, response.data, {
            excludeExtraneousValues: true,
            excludePrefixes: ['_']
        });
    }
}
