import {getDevice} from "framework7/shared/get-device";
import LogService from "@/services/log-service/LogService";
import FirebaseFcmToken from "@models/firebase/FirebaseFcmToken";

export default class FirebaseNotificationCordovaPluginService {
    public fcmToken: string | null = null;

    public async initPushNotifications(funcUpdateDeviceTokens: Function, funcCheckAuth: Function) {
        try {
            const self = this;
            if (getDevice().cordova) {
                // @ts-ignore
                cordova.plugins.firebase.messaging.requestPermission({forceShow: true})
                    .then(async () => {
                        const token = await self.retrieveAndSaveToken();
                        if (token && funcCheckAuth()) {
                            try {
                                funcUpdateDeviceTokens([new FirebaseFcmToken(token)]);
                            } catch (e) {}
                        }
                    })
            }
        } catch (e: any) {}
    }

    private async requestAuthorization() {
        const self = this;
        // @ts-ignore
        cordova.plugins.firebase.messaging.requestPermission({forceShow: true})
            .then(async () => {
                await self.retrieveAndSaveToken();
            })
    }

    private async retrieveAndSaveToken() {
        try {
            // @ts-ignore
            const token = await cordova.plugins.firebase.messaging.getToken();
            if (token) {
                localStorage.setItem("fcm-token", token);
            }
            return token;
        } catch (e: any) {
            LogService.of().error("FirebaseNotificationCordovaPluginService@checkAndSaveFcmToken", e.message);
        }
    }
}