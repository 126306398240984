import {Expose, Type} from "class-transformer";
import Currency from "@/entities/Currency";

export default class AgentsAmountRanges {
    @Expose({name: 'min'})
    private _min: number | null = null;

    @Expose({name: 'max'})
    private _max: number | null = null;

    @Expose({name: 'count'})
    private _count: number | null = null;

    @Expose({name: 'currency'})
    private _currency: Currency | null = null;

    public constructor(min: number | null, max: number | null) {
        this._min = min;
        this._max = max;
    }

    get currency(): Currency | null {
        return this._currency;
    }

    get max(): number | null {
        return this._max;
    }

    set max(value: number | null) {
        this._max = value;
    }

    get min(): number | null {
        return this._min;
    }

    set min(value: number | null) {
        this._min = value;
    }

    get count(): number | null {
        return this._count;
    }
}
