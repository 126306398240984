import {f7} from "framework7-vue"
import humps from "lodash-humps-ts"
// @ts-ignore
import store from '@target/ts/store/store'
import KodMobiError from "@/errors/KodMobiError";

export default class kodmobiApiService {
    private _url: String = import.meta.env.VITE_KODMOBI_API_BASE_URL;
    private static instance: kodmobiApiService | null = null
    private additionalHeaders: Map<string, string> = new Map();
    private _headers: any = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-api-key': import.meta.env.VITE_KODMOBI_API_KEY,
    }
    private _MAX_FETCH_TIMEOUT = 10000;

    public setConfigDomain(domain: string) {
        this._url = domain;
    }

    get url(): String {
        return this._url;
    }

    public setHeaderXApiKey(key: string) {
        this._headers['x-api-key'] = key;
    }

    public static getInstance() {
        if (kodmobiApiService.instance === null) {
            kodmobiApiService.instance = new kodmobiApiService()
        }

        return kodmobiApiService.instance
    }

    public setAdditionalHeader(key: string, value: string) {
        this.additionalHeaders.set(key, value);
    }

    public removeAdditionalHeader(key: string) {
        if (this.additionalHeaders.has(key)) {
            this.additionalHeaders.delete(key);
        }
    }

    public async get<T>(path: string, body: GetBody = {},): Promise<T> {
        return new Promise(async (resolve, reject) => {
            const params = this.buildParams(body)
            const response: Response = await fetch(`${this._url}${path}?${params}`, {
                method: "GET",
                headers: this.makeHeaders(false, false),
                // signal: AbortSignal.timeout(this._MAX_FETCH_TIMEOUT)
            })
            let result = await response.json();

            if (!response.ok) {
                reject(new KodMobiError(result.error, result.sys_message!, 400));
            }

            resolve(humps(result));
        });
    }

    public async post(path: string, body: FormData | any, isFile: boolean = false): Promise<Response> {
        const promise: Promise<Response> = new Promise(async (resolve, reject) => {
            let jsonFormat = true;
            if (body ! instanceof FormData) jsonFormat = false;

            const response: Response = await fetch(`${this._url}${path}`, {
                method: "POST",
                // signal: AbortSignal.timeout(this._MAX_FETCH_TIMEOUT),
                headers: this.makeHeaders(isFile, jsonFormat),
                body: jsonFormat ? JSON.stringify(body) : body,
            });
            let result: any = await response.json();
            if (!response.ok) reject(result);
            if (response.status === 401) {
                reject("No auth");
            }

            resolve(humps(result));
        });
        return promise;
    }

    private makeHeaders(isFile: boolean = false, json: boolean = false) {
        for (let [key, value] of this.additionalHeaders) {
            this._headers[key] = value;
        }

        return this._headers
    }

    private buildParams(data: Object) {
        const params = new URLSearchParams()

        Object.entries(data).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                value.forEach(value => params.append(`${key}[]`, value))
            } else {
                params.append(key, value)
            }
        });

        return params.toString()
    }
}

type PostBody = {
    [key: string]: any
}

type GetBody = {
    [key: string]: any
}

type Headers = {
    [key: string]: any
}
