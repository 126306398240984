<template>
<div class="payment" :class="'payment-' + payment.id" @click="toPayment">
    <div class="body">
        <div class="header-wrap">
            <div class="amount">
                <i class="icon round-bill-svg"></i>
                <div class="wrapper-numbers">
                    <span class="main" v-format-number>{{ Math.round(payment.fromAmount) }} {{ payment.fromCurrency.abbr }}</span>
                    <span class="convert">{{ $t("payment.income.operation-text") }} {{ payment.amount }} $</span>
                </div>
            </div>
            <div class="right-side">
          <span class="payment-status" :class="['confirmed', payment.operationType]">{{
                  $t("g.payments.statuses.CONFIRMED")
              }}</span>
            </div>
        </div>
        <div class="details-wrap">
            <p class="number">{{ $t('g.request') }} {{ payment.id }} <span
                class="data">{{ convertDatetime(payment.createdAt) }}</span></p>
            <div class="wrapper-right">
            </div>
        </div>
    </div>
</div>
</template>

<script setup lang="ts">
// @ts-ignore
import store from "@target/core/store/store";
import {PropType, Ref, toRef} from "vue";
import {f7} from "framework7-vue";
import PaymentIncome from "@/entities/PaymentIncome";
import dayjs from "dayjs";

const props = defineProps({
    payment: {
        type: Object as PropType<PaymentIncome>,
        required: true,
    }
});
const payment: Ref<PaymentIncome> = toRef(props, "payment");

async function toPayment() {
    try {
        // @ts-ignore
        const el = window.event.target.closest('.payment');
        el.classList.add("active-state");
        setTimeout(() => {
            el.classList.remove("active-state");
        }, 100);
        await store.dispatch("fetchPaymentIncome", payment.value);
        f7.views.current.router.navigate('/payment/income')
    } catch (e) {
        console.log(e);
    }
};

function convertDatetime(dateString: string) {
    return dayjs(dateString).format('DD.MM.YYYY HH:mm');
};
</script>
