import AbstractDocument from "@/services/firebase/firestore/documents/AbstractDocument";
import {DocumentTypes} from "@/services/firebase/firestore/documents/DocumentTypes";
import {Expose} from "class-transformer";

export default class DocumentWithdrawal extends AbstractDocument {
    @Expose({name: 'defaultAmount'})
    private _defaultAmount: number | null = null;
    @Expose({name: 'minP2PAmount'})
    private _minP2PAmount: number | null = null;
    @Expose({name: 'minWalletAmount'})
    private _minWalletAmount: number | null = null;
    protected _type: DocumentTypes = DocumentTypes.WITHDRAWAL;

    constructor() {
        super();
    }

    get defaultAmount(): number | null {
        return this._defaultAmount;
    }

    get minP2PAmount(): number | null {
        return this._minP2PAmount;
    }

    get minWalletAmount(): number | null {
        return this._minWalletAmount;
    }
}
