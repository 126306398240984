import { Expose } from "class-transformer";

export default class ModelChatSocketChat {
    @Expose({ name: "id" })
    private readonly _id: number;
    @Expose({ name: "paymentId" })
    private readonly _paymentId: number;
    @Expose({ name: "theme" })
    private readonly _theme: string;

    constructor(id: number, paymentId: number, theme: string) {
        this._id = id;
        this._paymentId = paymentId;
        this._theme = theme;
    }

    public get id() {
        return this._id;
    }

    public get paymentId() {
        return this._paymentId;
    }

    public get theme() {
        return this._theme;
    }
}
