<template>
    <div class="version-wrapper" ref="self">
        <p
            class="version"
            :class="[
                {'version_post-exists' : isExistsVersionPost},
            ]"
            @click="openPosts"
        >
            {{ $t('g.version') }} {{ currentVersion }}
            <i v-if="!isViewLastPost" class="icon i-svg-mark"></i>
        </p>
        <f7-button
            v-if="isCanShowUpdateButton && isNeedUpdate"
            class="theme-type mini"
            @click="openDeeplink"
        >
            {{ $t("g.update") }}
        </f7-button>
    </div>
</template>

<script setup lang="ts">
import {PropType, Ref, toRef} from "vue";
import ReleazioConfig from "@models/v2/releazio/config/ReleazioConfig";
import {ref} from "@vue/runtime-core";
import {computed} from "@vue/reactivity";

const self = ref(null);
const props = defineProps({
    releazioConfig: {
        type: Object as PropType<ReleazioConfig | null>,
        required: true
    },
    currentVersion: {
        type: Object as PropType<String>,
        required: true
    },
    isNeedUpdate: {
        type: Object as PropType<boolean>,
        required: true
    },
    isViewLastPost: {
        type: Object as PropType<Boolean>,
        required: true
    },
    isCanShowUpdateButton: {
        type: Object as PropType<Boolean>,
        required: true
    },
});
const versionConfig: Ref<ReleazioConfig | null> = toRef(props, 'releazioConfig');
const currentVersion: Ref<String> = toRef(props, 'currentVersion');
const isNeedUpdate: Ref<Boolean> = toRef(props, 'isNeedUpdate');
const isViewLastPost: Ref<Boolean> = toRef(props, 'isViewLastPost');
const isCanShowUpdateButton: Ref<Boolean> = toRef(props, 'isCanShowUpdateButton');
const isExistsVersionPost = computed(() => versionConfig.value?.postUrl);

const emits = defineEmits(["openDeeplink", "openPosts"]);

defineExpose({
    self: self,
});

function openDeeplink() {
    emits("openDeeplink");
}

function openPosts() {
    emits("openPosts");
}

</script>

<style lang="scss">
.version-wrapper {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    user-select: none;
    height: 26px;

    & > .version {
        @include text(12px, 400, var(--grey3), 12px);
        display: inline-flex;
        align-items: center;

        &_post-exists {
            background: var(--white1);
            padding: 6px 12px;
            border-radius: 100px;
        }

        .icon {
            margin-left: 6px;
        }

        .i-svg-mark {
            width: 8px;
            height: 8px;
            background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='8' height='8' rx='4' fill='%23FFB300'/%3E%3C/svg%3E%0A");
        }
    }
}
</style>