import {Expose, Type} from "class-transformer";
import ModelRates from "@models/v2/rates/ModelRates";

export default class ModelAccountCurrency {
    @Expose({name: "id"})
    private readonly _id: number;
    @Expose({name: "abbr"})
    private readonly _abbr: string;
    @Expose({name: "dSums"})
    private readonly _dSums: number[];
    @Expose({name: "desc"})
    private readonly _desc: string;
    @Expose({name: "name"})
    private readonly _name: string;
    @Expose({name: "rates"})
    @Type(() => ModelRates)
    private readonly _rates: ModelRates;

    constructor(
        id: number,
        abbr: string,
        dSums: number[],
        desc: string,
        name: string,
        rates: ModelRates
    ) {
        this._id = id;
        this._abbr = abbr;
        this._dSums = dSums;
        this._desc = desc;
        this._name = name;
        this._rates = rates;
    }

    public get id() {
        return this._id;
    }

    public get abbr() {
        return this._abbr;
    }

    public get desc() {
        return this._desc;
    }

    public get name() {
        return this._name;
    }

    public get dSums() {
        return this._dSums;
    }

    public get rates() {
        return this._rates;
    }
}
