// @ts-ignore
import ServiceCoreApi from "@/services/v2/service-core-api/ServiceCoreApi";
import {PayloadServiceCoreApi} from "@/services/v2/service-core-api/types";
import humps from "lodash-humps-ts";
import {ServiceCoreApiResponseError} from "@/services/v2/service-core-api/errors/ServiceCoreApiResponseError";

export default class ServiceReleazioApi {
    private static _instance: ServiceReleazioApi | null = null;
    private readonly _authNames = {requestName: 'Authorization'}
    private readonly _serviceApi: ServiceCoreApi;
    private readonly _baseHeaders: Record<string, string> = {};

    constructor() {
        this._serviceApi = new ServiceCoreApi(import.meta.env.VITE_RELEAZIO_API_URL);

        this._serviceApi.interceptors.request.use((config: RequestInit) => {
            config = {
                ...config,
                headers: {
                    ...this._baseHeaders,
                    ...config.headers
                },
            }

            const xToken = this.getToken();

            if (xToken) {
                (config.headers as Record<string, string>)[this._authNames.requestName] = xToken;
            }

            return config;
        });

        this._serviceApi.interceptors.response.use(
            (response) => humps(response),
            async (error: any | ServiceCoreApiResponseError) => {
                return Promise.reject(error);
            }
        );
    }

    public static of() {
        if (ServiceReleazioApi._instance === null) {
            ServiceReleazioApi._instance = new ServiceReleazioApi()
        }

        return ServiceReleazioApi._instance;
    }

    public async get<T>(
        path: string,
        payload: PayloadServiceCoreApi = {},
        config: RequestInit = {}
    ): Promise<T> {
        const {data} = await this._serviceApi.get<T>(path, config, payload);
        return data;
    }

    public async post<T>(
        path: string,
        payload: BodyInit | null | FormData | PayloadServiceCoreApi = {},
        config: RequestInit = {}
    ): Promise<T> {
        const {data} = await this._serviceApi.post<T>(path, config, payload);
        return data;
    }

    public async patch<T>(
        path: string,
        payload: PayloadServiceCoreApi = {},
        config: RequestInit = {}
    ): Promise<T> {
        const {data} = await this._serviceApi.patch<T>(path, config, payload);
        return data;
    }

    public async delete<T>(
        path: string,
        payload: PayloadServiceCoreApi = {},
        config: RequestInit = {}
    ): Promise<T> {
        const {data} = await this._serviceApi.delete<T>(path, config, payload);
        return data;
    }

    private getToken() {
        return import.meta.env.VITE_RELEAZIO_API_AUTH_KEY;
    }

    public setConfigDomain(value: string): void {
        this._serviceApi.url = value;
    }
}
