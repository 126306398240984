import ServiceAccount from "@/services/v2/data/service-account/ServiceAccount";

export const color = {
    name: 'format-number',
    fn: (el: HTMLLIElement, bind: any) => {
        const currencySign = ServiceAccount.of().accountCurrencyAbbr;
        let regex = new RegExp(`[${currencySign.value}$]`, 'g');
        if (el instanceof HTMLSpanElement || el instanceof HTMLParagraphElement) {
            let text = el.innerText;
            if (typeof bind.value !== 'undefined') {
                const sign = text.match(regex);
                text = bind.value.toString();
                if (sign) {
                    text += ` ${sign[0]}`;
                }
            }
            el.innerText = formatText(text);
        }
        if (el instanceof HTMLLIElement) {
            const input = el.querySelector('input') as HTMLInputElement;
            if (input) {
                input.value = formatText(input.value);
            }
        }
    }
}

function formatText(text: string): string {
    const currencySign = ServiceAccount.of().accountCurrencyAbbr;
    let regex = new RegExp(`[${currencySign.value}$]`, 'g');

    let sign = text.match(regex);

    if (sign === null) {
        sign = text.match(new RegExp("USDT", 'g'));
    }
    text = text.replace(/\s/g, '');
    const parts = text.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    let result = parts.join(".");

    if (sign) {
        let regex = new RegExp(`[${sign[0]}]`, 'g');
        result = result.replace(regex, ` ${sign[0]}`);
    }
    return result;
}
