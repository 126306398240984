import {Expose} from "class-transformer";

export default class ModelAccountContact {
    @Expose({name: 'email'})
    private readonly _email: string;
    @Expose({name: 'phone'})
    private readonly _phone: string;

    constructor(email: string, phone: string) {
        this._email = email;
        this._phone = phone;
    }

    public get email(): string {
        return this._email;
    }

    public get phone(): string {
        return this._phone;
    }
}
