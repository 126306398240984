import ServiceReleazioApi from "@/services/v2/service-releazio-api/ServiceReleazioApi";
// @ts-ignore
import AppController from "@target/components/App/ts/AppController";
import ReleazioConfigResponse from "@models/v2/releazio/config/ReleazioConfigResponse";
import {plainToInstance} from "class-transformer";
import KodmobiSettings from "@models/responses/kodmobi/KodmobiSettings";
import ReleazioConfigRequestDto from "@models/v2/releazio/config/ReleazioConfigRequestDto";

export default class RepositoryReleazio {
    private readonly _serviceReleazioApi: ServiceReleazioApi

    constructor(serviceReleazioApi: ServiceReleazioApi) {
        this._serviceReleazioApi = serviceReleazioApi;
    }

    public async fetchVersionConfig(releazioConfigRequestDto: ReleazioConfigRequestDto): Promise<ReleazioConfigResponse> {
        const response = await this._serviceReleazioApi.get<ReleazioConfigResponse>("", releazioConfigRequestDto.toPayload());
        return plainToInstance(ReleazioConfigResponse, response, {
            excludeExtraneousValues: true,
            excludePrefixes: ['_']
        });
    }
}