import {Expose} from "class-transformer";

export default class ModelAccountSettingsBan {
    @Expose({name: 'banAt'})
    private readonly _banAt: string;
    @Expose({name: 'comment'})
    private readonly _comment: string;
    @Expose({name: 'unbanAt'})
    private readonly _unbanAt: string;

    constructor(banAt: string, comment: string, unbanAt: string) {
        this._banAt = banAt;
        this._comment = comment;
        this._unbanAt = unbanAt;
    }

    public get banAt(): string {
        return this._banAt;
    }

    public get comment(): string {
        return this._comment;
    }

    public get unbanAt(): string {
        return this._unbanAt;
    }
}
