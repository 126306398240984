import {Expose} from "class-transformer";
import ModelAccountBalanceCurrency from "./ModelAccountBalanceCurrency";

export default class ModelAccountBalance {
    @Expose({name: "amount"})
    private readonly _amount: number;
    @Expose({name: "amountC"})
    private readonly _amountC: number;
    @Expose({name: "currency"})
    private readonly _currency: ModelAccountBalanceCurrency;
    @Expose({name: "isFrozen"})
    private readonly _isFrozen: boolean;
    @Expose({name: "rate"})
    private readonly _rate: number;

    constructor(
        amount: number,
        amountC: number,
        currency: ModelAccountBalanceCurrency,
        isFrozen: boolean,
        rate: number
    ) {
        this._amount = amount;
        this._amountC = amountC;
        this._currency = currency;
        this._isFrozen = isFrozen;
        this._rate = rate;
    }

    public get amount(): number {
        return this._amount;
    }

    public get amountC(): number {
        return this._amountC;
    }

    public get roundAmountC(): number {
        return Math.round(this._amountC);
    }

    public get currency(): ModelAccountBalanceCurrency {
        return this._currency;
    }

    public get isFrozen(): boolean {
        return this._isFrozen;
    }

    public get rate(): number {
        return this._rate;
    }
}
