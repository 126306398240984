import { plainToInstance } from "class-transformer";
import { validateOrReject } from "class-validator";
import ServiceMainApi from "@/services/v2/service-main-api/ServiceMainApi";
import ModelChatMessage from "@models/v2/chat/ModelChatMessage";
import ModelChatMessageAttachment from "@models/v2/chat/ModelChatMessageAttachment";
import ModelChat from "@models/v2/chat/ModelChat";

export default class RepositoryChat {
    public async fetchChatByMsid(msid: string): Promise<ModelChat> {
        const { data } = await ServiceMainApi.of().get<{ data: object }>(`/api/mobile/client/chat/${msid}`);

        const model: ModelChat = plainToInstance(ModelChat, data, {
            excludeExtraneousValues: true,
            excludePrefixes: ['_']
        });

        await validateOrReject(model);

        return model;
    }

    public async sendTextMessage(payload: { msid: string, message: string, wait_files?: number }): Promise<ModelChatMessage> {
        const { data } = await ServiceMainApi.of().post<{data: object}>('/api/mobile/client/chat', payload);

        const model: ModelChatMessage = plainToInstance(ModelChatMessage, data, {
            excludeExtraneousValues: true,
            excludePrefixes: ['_']
        });

        await validateOrReject(model);

        return model;
    }

    public async sendFileMessage(formData: FormData): Promise<ModelChatMessageAttachment> {
        const { data } = await ServiceMainApi.of().post<{ data: object }>('/api/mobile/client/chat/message/file', formData);

        const model: ModelChatMessageAttachment = plainToInstance(ModelChatMessageAttachment, data, {
            excludeExtraneousValues: true,
            excludePrefixes: ['_']
        });

        await validateOrReject(model);

        return model;
    }

    public async markMessageAsRead(payload: { chatId: number, messageId: number }) {
        await ServiceMainApi.of().patch("/api/mobile/client/chat/read", {
            chat_id: payload.chatId,
            message_ids: [payload.messageId]
        });
    }
}
