// Ошибки аккаунтов
export enum AccountError {
    AgentNotFound = 10030,
    AgentsUnavailableForAmount = 10033,
    AgentsUnavailableForRangeAmount  = 10034
}

// Ошибки транзакций
export enum TransactionError {
    TransactionNotFound = 10040,
    TransactionNotCreated = 10041, //TODO Нет текста в lang файлах
    InvalidTransactionOrAccessDenied = 10043,
    AccountActionsAlreadyConfirmed = 10044,
    TransactionAlreadyConfirmed = 10045,
    TransactionCompleted = 10046
}

// Ошибки заявок (покупки, BUY)
export enum BuyOrderError {
    OrderNotFound = 10050,
    OrderNotCreated = 10051,
    EmptyOrderNotCreated = 10052,
    InvalidOrderOrAccessDenied = 10053,
    ActionsAlreadyCompleted = 10054,
    UnableToCancelConfirmedOrder = 10055,
    UnableToCancelPreviouslyCancelledOrder = 10056,
    UnableToConfirmCancelledOrExpiredOrder = 10057,
    UnableToChangeOrderStatusIntercepted = 10058,
    InvalidOrderStatusForAction = 10059
}

// Ошибки заявок (продажи, SELL)
export enum SellOrderError {
    OrderNotFound = 10150,
    OrderNotCreated = 10151,
    InvalidOrderOrAccessDenied = 10153,
    ActionsAlreadyCompleted = 10154,
    UnableToCancelConfirmedOrder = 10155,
    UnableToCancelPreviouslyCancelledOrder = 10156,
    UnableToConfirmCancelledOrExpiredOrder = 10157,
    UnableToChangeOrderStatusIntercepted = 10158,
    InvalidOrderStatusForAction = 10159
}

// Ошибки отчетов пользователя (скриншоты оплаты)
export enum UserReportError {
    ReportNotFound = 10060,
    ReportNotCreated = 10061,
    FileUploadFailed = 10062,
    InvalidFileFormatOrSize = 10063,
    UnableToUploadFileForCurrentOrderStatus = 10064,
    ScreenshotStillActive = 10065,
    ScreenshotLimitReached = 10066
}

// Ошибки пользователя (клиента)
export enum UserError {
    ClientNotFound = 10070,
    ProfileDeletionFailed = 10076,
    ExceededOrderLimit = 10077,
    UserTemporarilyBlocked = 10078,
    InsufficientBalance = 10079,
    ClientSuspicionActionDeclined = 100788,
    ActionDeclinedDueToNegativeBalance = 100781 //TODO Нет текста в lang файлах
}

// Ошибки чата (клиент-агент)
export enum ChatError {
    ChatNotFound = 10080,
    ChatCreationFailed = 10081,
    MessageSendFailed = 10082,
    AccessDenied = 10083,
    AttachmentUploadFailed = 10084,
    AgentNotFound = 10086, //TODO Нет текста в lang файлах
    SystemDisabledOrUserBlocked = 10087, //TODO Нет текста в lang файлах
    ChatTemporarilyUnavailable = 10088,
    InvalidAdminHashAccessDenied = 10089
}

// Ошибки валют
export enum CurrencyError {
    CurrencyNotFound = 10090 //TODO Нет текста в lang файлах
}

// Ошибки платежных систем
export enum PaymentSystemError {
    PaymentSystemNotFound = 10100 //TODO Нет текста в lang файлах
}

// Ошибки реквизитов Агента
export enum AgentRequisiteError {
    RequisiteNotFound = 10200,
    RequisiteCreationFailed = 10201, //TODO Нет текста в lang файлах
    InvalidRequisiteAccessDenied = 10203,
    SetDefaultRequisiteFailed = 10208
}

// Ошибки реквизитов Клиента
export enum ClientRequisiteError {
    RequisiteNotFound = 10300,
    RequisiteCreationFailed = 10301,
    RequisiteUpdateFailed = 10302,
    InvalidRequisiteAccessDenied = 10303,
    SetDefaultRequisiteFailed = 10308,
    WithdrawalApprovalFailed = 10305,
    AddressAlreadyTaken = 10310
}

// Ошибки выводов/трансферов Клиентов
export enum ClientTransferError {
    PaymentNotFound = 10600,
    PaymentCreationFailed = 10601,
    InvalidPaymentAccessDenied = 10603, //TODO Нет текста в lang файлах
    PayoutProcessingFailed = 10606,
    BelowMinimumAmount = 10608,
    AboveMaximumAmount = 10609
}

// Ошибки выплат Агентам
export enum AgentPayoutError {
    PayoutNotFound = 10400, //TODO Нет текста в lang файлах
    PayoutCreationFailed = 10401, //TODO Нет текста в lang файлах
    PayoutProcessingFailed = 10402,
    InvalidPayoutAccessDenied = 10403, //TODO Нет текста в lang файлах
    InsufficientBalance = 10405,
    PayoutProcessingFailedDueToOrderStatus = 10407,
    AccountSuspended = 10408
}

// Ошибки кошельков
export enum WalletError {
    WalletNotFound = 10500, //TODO Нет текста в lang файлах
    WalletCreationFailed = 10501, //TODO Нет текста в lang файлах
    WalletUpdateFailed = 10502,
    InvalidWalletAccessDenied = 10503, //TODO Нет текста в lang файлах
    WalletApprovalFailed = 10505,
    InvalidWalletType = 10507,
    SetDefaultWalletFailed = 10508,
    WalletDeletionFailed = 10509
}

// Ошибки рейнджей сумм
export enum AmountRangeError {
    UnableToGetAmountRange = 10700
}
