import RepositoryRates from "@/repositories/v2/repository-rates/RepositoryRates";
import ModelRates from "@models/v2/rates/ModelRates";
import { ref, Ref } from "vue";

export default class ServiceRates {
    private static _instance: ServiceRates | null = null;
    private readonly _repositoryRates: RepositoryRates;
    private readonly _rates: Ref<ModelRates | null>;

    constructor() {
        this._rates = ref(null);
        this._repositoryRates = new RepositoryRates();
    }

    public static of() {
        if (ServiceRates._instance === null) {
            ServiceRates._instance = new ServiceRates();
        }

        return ServiceRates._instance;
    }

    public get rates(): Ref<ModelRates | null> {
        return this._rates;
    }

    public set rates(value: ModelRates | null) {
        this._rates.value = value;
    }

    public async fetchRates() {
        this._rates.value = await this._repositoryRates.fetchRates();
    }
}
