<template>
    <div class="chat-message-pdf" @click="download">
        <i class="icon chat-file-icon"></i>
        <div class="chat-message-pdf_content">
            <p class="chat-message-pdf_name">{{ file.clientFilename }}</p>
            <p class="chat-message-pdf_size">{{ size() }} &#183; PDF</p>
        </div>
    </div>
</template>

<script setup lang="ts">
import { getDevice } from "framework7/shared/get-device";
import ModelChatMessageAttachment from "@models/v2/chat/ModelChatMessageAttachment";

interface Props {
    file: ModelChatMessageAttachment
}

const props = defineProps<Props>();

const size = () => {
    const length = String(props.file.fileSize).length;
    if (length < 7) {
        return Math.round(props.file.fileSize / 1024).toFixed(1) + ' KB';
    } else {
        return (Math.round(props.file.fileSize / 1024) / 1000).toFixed(1) + ' MB';
    }
};
const download = () => {
    try {
        if (getDevice().android) {
            // @ts-ignore
            window.webInterface.onMessage(JSON.stringify({
                "deeplink": props.file.filePath
            }));
        }
        if (getDevice().ios) {
            // @ts-ignore
            window.webkit.messageHandlers.iosListener.postMessage(JSON.stringify({
                "deeplink": props.file.filePath
            }));
        }
    } catch (e) {
        window.open(props.file.filePath, '_blank');
    }
}
</script>

<style lang="scss">
.chat-message-pdf {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px 12px 0 12px;
    text-align: left;

    &_content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &_name {
        @include text(14px, 400, var(--white1), 19.2px);
        -webkit-line-clamp: 1;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    &_size {
        @include text(14px, 400, var(--white1), 16.8px);
        opacity: 0.7;
    }
}
</style>
