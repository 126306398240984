import { Expose, Type } from "class-transformer";
import ModelChatMessageAttachment from "@models/v2/chat/ModelChatMessageAttachment";

export default class ModelChatMessage {
    @Expose({ name: "id" })
    private readonly _id: number;
    @Expose({ name: "chatId" })
    private readonly _chatId: number;
    @Expose({ name: "message" })
    private readonly _message: string;
    @Expose({ name: "isRead" })
    private readonly _isRead: boolean;
    @Expose({ name: "ln" })
    private readonly _ln: number;
    @Expose({ name: "senderName" })
    private readonly _senderName: string;
    @Expose({ name: 'senderType' })
    private readonly _senderType: string;
    @Expose({ name: 'waitFiles' })
    private readonly _waitFiles: number;
    @Expose({ name: "attachments"})
    @Type(() => ModelChatMessageAttachment)
    private readonly _attachments: ModelChatMessageAttachment[];
    @Expose({ name: "createdAt" })
    private readonly _createdAt: string;
    @Expose({ name: "updatedAt" })
    private readonly _updatedAt: string;

    constructor(
        id: number,
        chatId: number,
        message: string,
        isRead: boolean,
        ln: number,
        senderName: string,
        senderType: string,
        waitFiles: number,
        attachments: ModelChatMessageAttachment[],
        createdAt: string,
        updatedAt: string
    ) {
        this._id = id;
        this._chatId = chatId;
        this._message = message;
        this._isRead = isRead;
        this._ln = ln;
        this._senderName = senderName;
        this._senderType = senderType;
        this._waitFiles = waitFiles;
        this._attachments = attachments;
        this._createdAt = createdAt;
        this._updatedAt = updatedAt;
    }

    public get id(): number {
        return this._id;
    }

    public get chatId(): number {
        return this._chatId;
    }

    public get message() {
        return this._message;
    }

    public get isRead() {
        return this._isRead;
    }

    public get ln() {
        return this._ln;
    }

    public get senderName() {
        return this._senderName;
    }

    public get senderType() {
        return this._senderType;
    }

    public get waitFiles() {
        return this._waitFiles;
    }

    public get attachments() {
        return this._attachments;
    }

    public get createdAt() {
        return this._createdAt;
    }

    public get updatedAt() {
        return this._updatedAt;
    }
}
