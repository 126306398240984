<template>
    <f7-views class="safe-areas main-views" ref="main-views">
        <f7-fab-buttons v-if="socketIsConnecting" position="right-top" class="socket-state">
            <div class="loader-2 center"><span></span></div>
        </f7-fab-buttons>
        <f7-view main :url="MAIN_URL" :preload-previous-page="false" :ios-swipe-back="false"></f7-view>
        <f7-view name="higher" url="/higher" :preload-previous-page="false" :ios-swipe-back="false"></f7-view>

        <hunting-agents-toast />
    </f7-views>
</template>

<script setup lang="ts">
// @ts-ignore
import AppController from "@target/components/App/ts/AppController";
import HuntingAgentsToast from "@components/toasts/HuntingAgentsToast.vue";

const vc = AppController.getInstance();
const MAIN_URL = detectMainUrl();
const socketIsConnecting = vc.socketIsConnecting;

function detectMainUrl() {
    const {payment} = vc.paymentService;
    if (payment.value && payment.value.msid !== null) {
        return "/payment"
    }
    return "/payments";
}
</script>

<style lang="scss">
</style>
