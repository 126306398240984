import {value} from "dom7";

export default class ReleazioPostsPageController {
    private _openPost: boolean = false;
    private static _instance: ReleazioPostsPageController | null = null;

    get openPost(): boolean {
        return this._openPost;
    }

    set openPost(value: boolean) {
        this._openPost = value;
    }

    public static of() {
        if (ReleazioPostsPageController._instance === null) ReleazioPostsPageController._instance = new ReleazioPostsPageController();
        return ReleazioPostsPageController._instance;
    }
}