import {autobind} from "@/decorators/autobind";
import ViewController from "@/interfaces/ViewController";
import i18n from "@/langs/i18n";
import {f7} from "framework7-vue";
import {ref, ToRef} from "vue";
import {ChatAttachmentData} from "@/types/ChatAttachmentData";
import ServiceChat from "@/services/v2/data/service-chat/ServiceChat";
import ModelChatMessage from "@models/v2/chat/ModelChatMessage";
import ModelChatMessageAttachment from "@models/v2/chat/ModelChatMessageAttachment";

export default class ChatViewController implements ViewController {
    private static _instance?: ChatViewController;
    public attachments: ToRef<ChatAttachmentData[]> = ref([]);

    static getInstance(): ChatViewController {
        if (typeof this._instance === "undefined") this._instance = new ChatViewController();
        return this._instance;
    }

    @autobind
    public async uploadFile(e: Event): Promise<boolean> {
        const file = (e.target as HTMLInputElement).files![0];

        if (!this.isFileSizeValid(file) || !this.isExtensionValid(file)) {
            return false;
        }

        this.attachFile(file);
        return true;
    }

    private isFileSizeValid(file: File): boolean {
        const fileSize = file.size / 1024 / 1024;
        if (fileSize > 10) {
            //@ts-ignore
            f7.dialog.alert(i18n.global.t("g.errors.chat.max-size"), i18n.global.t("g.errors.alert.default-title"));
            return false;
        }
        return true;
    }

    private isExtensionValid(file: File): boolean {
        const extension = file.type.split('/')[1];
        if (!['jpeg', 'jpg', 'png', 'pdf'].includes(extension)) {
            f7.dialog.alert(i18n.global.t("g.errors.chat.invalid-format"), i18n.global.t("g.errors.alert.default-title"));
            return false;
        }
        return true;
    }

    private attachFile(file: File): void {
        this.attachments.value.push({
            file: file,
            path: URL.createObjectURL(file)
        });
        this.filterAttachmentsByTypes();
    }

    // private async switchFlowByFileType(file: File) {
    //     if (file.type.match('.*pdf.*')) {
    //         await this.sendTextMessage();
    //     } else {
    //         this.attachFile(file);
    //     }
    // }

    public filterAttachmentsByTypes() {
        const types = ['pdf'];
        const matchValues = types.toString().split(',').join('|');
        return this.attachments.value.filter((el: ChatAttachmentData) => !el.file.type.match(`(${matchValues})`))
    }

    public fileIsPDF(file: File): boolean {
        return !!file.type.match('.*pdf.*');
    }

    @autobind
    public removeAttachment() {
        this.attachments.value.pop();
    }

    public async sendTextMessage(payload: {
        msid: string,
        message: string,
        wait_files?: number
    }): Promise<ModelChatMessage> {
        return await ServiceChat.of().sendTextMessage(payload);
    }

    public async sendFileMessage(payload: { chatId: number, messageId: number, file: File }): Promise<ModelChatMessageAttachment> {
        return await ServiceChat.of().sendFileMessage(payload);
    }

    destructor(): void {
        ChatViewController._instance = undefined;
    }
}
