import { ref, Ref } from "vue";
import { plainToInstance } from "class-transformer";
import ModelChat from "@models/v2/chat/ModelChat";
import RepositoryChat from "@/repositories/v2/repository-chat/RepositoryChat";
import ModelChatF7 from "@models/v2/chat/f7/ModelChatF7";
import ModelChatF7Message from "@models/v2/chat/f7/ModelChatF7Message";
import i18n from "@/langs/i18n";
import dayjs from "dayjs";
import ModelChatSocket from "@models/v2/chat/ModelChatSocket";
import ModelChatF7Socket from "@models/v2/chat/f7/ModelChatF7Socket";
import ModelChatMessage from "@models/v2/chat/ModelChatMessage";
import humps from "lodash-humps-ts";

export default class ServiceChat {
    private static _instance: ServiceChat | null = null;
    private _repositoryChat: RepositoryChat;
    private readonly _chat: Ref<ModelChatF7 | null>;

    constructor() {
        this._chat = ref(null);
        this._repositoryChat = new RepositoryChat();
    }

    public static of() {
        if (ServiceChat._instance === null) {
            ServiceChat._instance = new ServiceChat();
        }

        return ServiceChat._instance;
    }

    public get chat(): Ref<ModelChatF7 | null> {
        return this._chat;
    }

    public set chat(value: ModelChatF7 | null) {
        this._chat.value = value;
    }

    public readMessages() {
        this._chat.value?.messages.forEach((message) => {
            if (!message.isRead) {
                message.isRead = true;
            }
        });
    }

    public readMessage(messageId: number) {
        if (this._chat.value) {
            let start = 0, end = this._chat.value.messages.length - 1;

            while (start <= end) {
                let mid = Math.floor((start + end) / 2);
                if (this._chat.value.messages[mid].id === messageId) {
                    this._chat.value.messages[mid].isRead = true;
                    break;
                } else if (this._chat.value.messages[mid].id < messageId)
                    start = mid + 1;
                else
                    end = mid - 1;
            }
        }
    }

    public addMessage(message: ModelChatF7Message) {
        this._chat.value?.messages.push(message);
    }

    public async fetchChatByMsid(msid: string) {
        return await this._repositoryChat.fetchChatByMsid(msid);
    }

    public async sendTextMessage(payload: { msid: string, message: string, wait_files?: number }) {
        return await this._repositoryChat.sendTextMessage(payload);
    }

    public async sendFileMessage(payload: { chatId: number, messageId: number, file: File }) {
        const formData = new FormData();
        formData.append('chat_id', payload.chatId.toString());
        formData.append('message_id', payload.messageId.toString());
        formData.append('file', payload.file);

        return await this._repositoryChat.sendFileMessage(formData);
    }

    public async markMessageAsRead(payload: { chatId: number, messageId: number }) {
        return await this._repositoryChat.markMessageAsRead(payload);
    }

    public modelChatF7MessageFactory(message: ModelChatMessage) {
        const model = plainToInstance(ModelChatF7Message, message, {
            excludeExtraneousValues: true,
            excludePrefixes: ['_']
        });

        this.setRequiredPropertiesF7Message(model);

        return model;
    }

    public modelChatF7Factory(chat: ModelChat): ModelChatF7 {
        const model = plainToInstance(ModelChatF7, chat, {
            excludeExtraneousValues: true,
            excludePrefixes: ['_']
        });

        model.messages.forEach((message: ModelChatF7Message) => {
            this.setRequiredPropertiesF7Message(message);
        });

        return model;
    }

    public modelChatSocketFactory(data: object): ModelChatSocket {
        return plainToInstance(ModelChatSocket, humps(data) as object, {
            excludeExtraneousValues: true,
            excludePrefixes: ['_']
        });
    }

    public modelChatF7SocketFactory(data: ModelChatSocket): ModelChatF7Socket {
        const model: ModelChatF7Socket = plainToInstance(ModelChatF7Socket, data, {
            excludeExtraneousValues: true,
            excludePrefixes: ['_']
        });

        this.setRequiredPropertiesF7Message(model.message);

        return model;
    }

    private setRequiredPropertiesF7Message(message: ModelChatF7Message) {
        message.name = this.transformName(message.senderName);
        message.type = this.transformType(message.senderType);
        message.createdAtUnix = this.formatTimestamp(message.createdAt);
        message.updatedAtUnix = this.formatTimestamp(message.updatedAt);
    }

    private transformName(value: string) {
        //@ts-ignore
        return value === 'admin' ? i18n.global.t('g.admin') : value;
    }

    private transformType(value: string) {
        return ["agent", "admin"].includes(value) ? 'received' : 'sent';
    }

    private formatTimestamp(value: string) {
        return dayjs(value).unix();
    }
}
