export default class ServerResponseError extends Error {
    private readonly _code: number;
    protected readonly _type: string = 'SERVER_RESPONSE_ERROR';

    constructor(message: string, code: number = 500) {
        super(message);

        this._code = code;

        Object.setPrototypeOf(this, ServerResponseError.prototype);
    }

    public get code() {
        return this._code;
    }

    public get type() {
        return this._type;
    }
}
