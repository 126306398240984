import {Expose, Type} from "class-transformer";
import ModelAccountBalance from "./ModelAccountBalance";
import ModelAccountCurrency from "./ModelAccountCurrency";
import ModelAccountSettings from "./ModelAccountSettings";
import ModelAccountProject from "./ModelAccountProject";
import ModelAccountContact from "./ModelAccountContact";

export default class ModelAccount {
    @Expose({name: "id"})
    private readonly _id: number;
    @Expose({name: "authExp"})
    private readonly _authExp: string;
    @Expose({name: "balance"})
    @Type(() => ModelAccountBalance)
    private _balance: ModelAccountBalance;
    @Expose({name: "contact"})
    @Type(() => ModelAccountContact)
    private readonly _contact: ModelAccountContact;
    @Expose({name: "currency"})
    @Type(() => ModelAccountCurrency)
    private readonly _currency: ModelAccountCurrency;
    @Expose({name: "login"})
    private readonly _login: string;
    @Expose({name: "shortName"})
    private readonly _shortName: string;
    @Expose({name: "project"})
    @Type(() => ModelAccountProject)
    private readonly _project: ModelAccountProject;
    @Expose({name: "settings"})
    @Type(() => ModelAccountSettings)
    private readonly _settings: ModelAccountSettings;
    @Expose({name: "createdAt"})
    private readonly _createdAt: string;
    @Expose({name: "updatedAt"})
    private readonly _updatedAt: string;

    constructor(
        id: number,
        authExp: string,
        balance: ModelAccountBalance,
        contact: ModelAccountContact,
        currency: ModelAccountCurrency,
        login: string,
        shortName: string,
        project: ModelAccountProject,
        settings: ModelAccountSettings,
        createdAt: string,
        updatedAt: string
    ) {
        this._id = id;
        this._authExp = authExp;
        this._balance = balance;
        this._contact = contact;
        this._currency = currency;
        this._login = login;
        this._shortName = shortName;
        this._project = project;
        this._settings = settings;
        this._createdAt = createdAt;
        this._updatedAt = updatedAt;
    }

    public get id(): number {
        return this._id;
    }

    public get authExp(): string {
        return this._authExp;
    }

    public get balance(): ModelAccountBalance {
        return this._balance;
    }

    public set balance(value: ModelAccountBalance) {
        this._balance = value;
    }

    public get contact(): ModelAccountContact {
        return this._contact;
    }

    public get currency(): ModelAccountCurrency {
        return this._currency;
    }

    public get login(): string {
        return this._login;
    }

    public get shortName(): string {
        return this._shortName;
    }

    public get project(): ModelAccountProject {
        return this._project;
    }

    public get settings(): ModelAccountSettings {
        return this._settings;
    }

    public get createdAt(): string {
        return this._createdAt;
    }

    public get updatedAt(): string {
        return this._updatedAt;
    }
}
