export default class ReleazioConfigRequestDto {
    private readonly _appId: string;
    private readonly _appVersionCode: string;
    private readonly _phoneLocaleLanguage: string;

    public constructor(
        appId: string,
        appVersionCode: string,
        phoneLocaleLanguage: string
    ) {
        this._appId = appId;
        this._appVersionCode = appVersionCode;
        this._phoneLocaleLanguage = phoneLocaleLanguage;
    }

    public toPayload() {
        return {
            app_id: this._appId,
            app_version_code: this._appVersionCode,
            phone_locale_language: this._phoneLocaleLanguage
        }
    }
}