import RepositoryReleazio from "@/repositories/v2/repository-releazio/RepositoryReleazio";
import ReleazioConfigRequestDto from "@models/v2/releazio/config/ReleazioConfigRequestDto";
import ReleazioConfig from "@models/v2/releazio/config/ReleazioConfig";
import {ToRef, ref} from "vue";
import RouterService from "@/services/RouterService";
import {getDevice} from "framework7/shared/get-device";

export default class ServiceReleazio {
    private readonly _repositoryReleazio: RepositoryReleazio;
    private _initialized: boolean = false;
    private _configs: ReleazioConfig[];
    private readonly _activeConfig: ToRef<ReleazioConfig | null>;
    private _isNeedUpdatePopupOpened = false;
    private _isNeedUpdatePopupWillReopen = false;
    private _isNeedUpdate: ToRef<boolean> = ref(false);
    private _isViewedLastPost = ref(true);
    private readonly _bundleId: string;
    private readonly _version: string;

    public constructor(repositoryReleazio: RepositoryReleazio, version: string = "1.0.0") {
        this._repositoryReleazio = repositoryReleazio;
        this._configs = [];
        this._activeConfig = ref(null);
        this._bundleId = import.meta.env.VITE_APP_BUNDLE_ID ?? "undefined";
        this._version = version;
    }

    get initialized(): boolean {
        return this._initialized;
    }

    set isNeedUpdatePopupWillReopen(value: boolean) {
        this._isNeedUpdatePopupWillReopen = value;
    }

    public async fetchVersionConfig(lang: string) {
        const releazioConfigRequestDto = new ReleazioConfigRequestDto(this._bundleId, this._version, lang);
        const {data} = await this._repositoryReleazio.fetchVersionConfig(releazioConfigRequestDto);
        this._configs = data;
        if (data.length > 0) this._activeConfig.value = data[0];
        this._initialized = true;
    }

    get isNeedUpdatePopupOpened(): boolean {
        return this._isNeedUpdatePopupOpened;
    }

    get isNeedUpdatePopupWillReopen(): boolean {
        return this._isNeedUpdatePopupWillReopen;
    }

    public updateViewPost() {
        if (this.activeConfig.value?.appVersionCode) {
            const storageVersion = localStorage.getItem("releazio_is_view_post_version");

            if (!storageVersion) {
                this._isViewedLastPost.value = false;
                return;
            }

            const versionReleazio = Number(this.activeConfig.value.appVersionCode.replace(/[^\w\s]/gi, ''));
            const storageIntVersion = Number(storageVersion.replace(/[^\w\s]/gi, ''));

            if (Number.isNaN(versionReleazio) || Number.isNaN(storageIntVersion)) return;
            if (versionReleazio !== storageIntVersion) this._isViewedLastPost.value = false;
            else this._isViewedLastPost.value = true;
        }
    }

    public readViewPost() {
        if (this._activeConfig.value?.appVersionName) {
            localStorage.setItem("releazio_is_view_post_version", this._activeConfig.value?.appVersionName);
        }
    }

    get isViewedLastPost(): ToRef<boolean> {
        return this._isViewedLastPost;
    }

    get isNeedUpdate() {
        return this._isNeedUpdate;
    }

    get configs(): ReleazioConfig[] {
        return this._configs;
    }

    get activeConfig(): ToRef<ReleazioConfig | null> {
        return this._activeConfig;
    }

    public openNeedUpdatePopup() {
        if (!getDevice().cordova) return;
        if (this.activeConfig.value) {
            if (this.activeConfig.value.updateType === 1) return;
        }
        this.addPopupBackdrop();
        this.showNeedUpdatePopup();
        this._isNeedUpdatePopupOpened = true;
        this._isNeedUpdatePopupWillReopen = false;
    }

    public closeNeedUpdatePopup(force: boolean = false) {
        if (this.activeConfig.value) {
            if (this.activeConfig.value.updateType === 3 && !force) return;
        }
        this.removePopupBackdrop();
        this.hideNeedUpdatePopup();
        this._isNeedUpdatePopupOpened = false;
    }

    private showNeedUpdatePopup() {
        const el: HTMLElement | null = document.querySelector(".releazio-need-update-popup");
        if (el != null) {
            el.style.display = "block";
            setTimeout(() => {
                el.style.opacity = "1";
                el.classList.remove("hide");
            }, 314)
        }
    }

    private hideNeedUpdatePopup() {
        const el: HTMLElement | null = document.querySelector(".releazio-need-update-popup");
        if (el != null) {
            el.style.opacity = "0";
            el.classList.remove("hide");
            setTimeout(() => {
                el.style.display = "none";
            }, 314)
        }
    }

    private addPopupBackdrop() {
        const el = document.createElement("div");
        el.classList.add("releazio-need-update-popup_backdrop");
        const parent = document.getElementById("framework7-root");
        if (parent) parent.appendChild(el);
        setTimeout(() => {
            el.style.opacity = "1";
        }, 314);
    }

    private removePopupBackdrop() {
        const el: HTMLElement | null = document.querySelector(".releazio-need-update-popup_backdrop");
        if (el) {
            el.style.opacity = "0";
            setTimeout(() => {
                el.remove();
            }, 314);
        }
    }

    public checkCurrentVersionOld() {
        if (this.activeConfig.value?.appVersionCode) {
            const versionReleazio = Number(this.activeConfig.value.appVersionCode.replace(/[^\w\s]/gi, ''));
            const versionCurrent = Number(this._version.replace(/[^\w\s]/gi, ''));

            if (Number.isNaN(versionReleazio) || Number.isNaN(versionCurrent)) return;
            if (versionReleazio > versionCurrent) this._isNeedUpdate.value = true;
        }
    }

    public openDeepLink() {
        if (this.activeConfig.value && this.activeConfig.value.appUrl) {
            // @ts-ignore
            window.cordova.InAppBrowser.open(this.activeConfig.value.appUrl, '_system');
        }
    }

    public hasVersionPost() {
        return this._activeConfig.value?.postUrl !== null;
    }

    public initVersionEvents(element: HTMLElement, timer: number | null, serviceRouter: RouterService) {
        if (element) {
            element.addEventListener("touchstart", () => {
                timer = setTimeout(() => {
                    serviceRouter.toTechPage();
                }, 500);
            });

            element.addEventListener("mousedown", () => {
                timer = setTimeout(() => {
                    serviceRouter.toTechPage();
                }, 500);
            });

            element.addEventListener("touchend", () => {
                if (timer) {
                    clearTimeout(timer);
                }
            });

            element.addEventListener("mouseup", () => {
                if (timer) {
                    clearTimeout(timer);
                }
            });
        }
    }
}