import ServiceAccount from "@/services/v2/data/service-account/ServiceAccount";

//TODO в будущем переписать директиву, чтобы можно было передать Abbr через bind
export const color = {
    name: 'format-number',
    fn: (el: HTMLLIElement, bind: any) => {
        const currencySign = ServiceAccount.of().accountCurrencyAbbr;
        let regex = new RegExp(`[${currencySign.value}$]`, 'g');
        if (el instanceof HTMLSpanElement || el instanceof HTMLParagraphElement) {
            let text = el.innerText;
            if (typeof bind.value !== 'undefined') {
                const sign = text.match(regex);
                text = parseFloat(bind.value).toString();
                if (sign) {
                    text += ` ${sign[0]}`;
                }
            }
            el.innerText = formatText(text);
        }
        if (el instanceof HTMLLIElement) {
            const input = el.querySelector('input') as HTMLInputElement;
            if (input) {
                input.value = formatText(input.value);
            }
        }
    }
}

export function formatText(text: string): string {
    const currencySign = ServiceAccount.of().accountCurrencyAbbr;
    let regex = new RegExp(`[${currencySign.value}$]`, 'g');

    let sign = text.match(regex);

    if (sign === null) {
        sign = text.match(new RegExp("USDT", 'g'));
    }
    text = text.replace(/\s/g, '');
    const parts = text.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    let result = parts.join(".");
    if (sign) {
        let pattern= `${sign[0]}`;
        if (sign[0].length === 1) {
            pattern = `[${sign[0]}]`;
        }
        let regex = new RegExp(pattern, 'g');
        result = result.replace(regex, ` ${sign[0]}`);
    }
    return result;
}
