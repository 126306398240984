import {Expose, Type} from "class-transformer";
import ReleazioConfig from "@models/v2/releazio/config/ReleazioConfig";

export default class ReleazioConfigResponse {
    @Expose({name: 'data'})
    @Type(() => ReleazioConfig)
    private readonly _data: ReleazioConfig[];
    @Expose({name: 'homeUrl'})
    private readonly _homeUrl: string;

    public constructor(
        data: ReleazioConfig[],
        homeUrl: string
    ) {
        this._data = data;
        this._homeUrl = homeUrl;
    }

    get data(): ReleazioConfig[] {
        return this._data;
    }

    get homeUrl(): string {
        return this._homeUrl;
    }
}