import ServerResponseError from "./ServerResponseError";

export default class KodMobiError extends Error {
    private readonly _code: number;
    private readonly _sysMessage: string;
    private readonly _type: string = 'KodMobiError';

    constructor(message: string, sysMessage: string, code: number) {
        super(message);

        this._code = code;
        this._sysMessage = sysMessage;

        Object.setPrototypeOf(this, ServerResponseError.prototype);
    }

    public get code() {
        return this._code;
    }

    public get sysMessage() {
        return this._sysMessage;
    }

    public get type() {
        return this._type;
    }
}
