<template>
    <f7-page class="releazio-posts-page" :page-content="false" @page:afterout="afterOutPageEvent">
        <f7-navbar class="releazio-posts-page__navbar">
            <f7-nav-left>
                <a href="#" class="link icon-only" @click="back">
                    <i class="icon-back icon"></i>
                </a>
            </f7-nav-left>
            <f7-nav-title>
                <p class="text">{{ $t("releazio-posts.title") }}</p>
                <p class="version">
                    <span>{{ $t("releazio-posts.version") }} {{ currentVersion }}</span>
                    <span v-if="isCanShowUpdateState && isNeedUpdate">&nbsp;<i
                        class="icon icon-arrow-right"></i>&nbsp;<span class="next-version">{{
                            activeConfigVersion
                        }}</span>
                    </span>
                </p>
            </f7-nav-title>
            <f7-nav-right>
                <f7-button v-if="isCanShowUpdateState && isNeedUpdate" class="theme-type mini" @click="openDeeplink">
                    {{ $t("releazio-posts.button") }}
                </f7-button>
            </f7-nav-right>
        </f7-navbar>
        <f7-page-content>
            <div class="loading-wrapper" v-if="loading">
                <f7-preloader/>
            </div>
            <iframe
                id="iframe"
                class="iframe"
                :src="iFrameUrl"
            />
        </f7-page-content>
    </f7-page>
</template>

<script setup lang="ts">
import {onMounted, ref} from "vue";
import {f7} from "framework7-vue";
// @ts-ignore
import AppController from "@target/components/App/ts/AppController";
import ServiceReleazio from "@/services/v2/service-releazio/ServiceReleazio";
import {getDevice} from "framework7/shared/get-device";
import {computed} from "@vue/reactivity";
import ReleazioPostsPageController from "@/views/releazio-posts/ReleazioPostsPageController";

const serviceReleazio = AppController.of().service<ServiceReleazio>("releazio");
const currentVersion = import.meta.env.VITE_VERSION ?? "1.0.0";
const isNeedUpdate = serviceReleazio!.isNeedUpdate;
const activeConfigVersion = serviceReleazio!.activeConfig.value!.appVersionName;
const postsUrl = serviceReleazio!.activeConfig.value!.postsUrl!;
const postUrl = serviceReleazio!.activeConfig.value!.postUrl!;
const loading = ref(true);
const isCanShowUpdateState = computed(() => getDevice().cordova);
const vc = ReleazioPostsPageController.of();
const iFrameUrl = computed(() => {
    if (vc.openPost) return postUrl;
    else return postsUrl;
});

function afterOutPageEvent() {
    if (serviceReleazio) {
        if (serviceReleazio.isNeedUpdatePopupWillReopen) {
            serviceReleazio.openNeedUpdatePopup();
        }
    }
}

function back() {
    f7.view.main.router.back();
}

function openDeeplink() {
    if (serviceReleazio) {
        serviceReleazio.openDeepLink();
    }
}

onMounted(() => {
    const iframe: HTMLElement | null = document.getElementById("iframe");
    if (iframe) {
        iframe.addEventListener("load", () => {
            loading.value = false;
            setTimeout(() => {
                iframe.style.opacity = 1;
            }, 314);
        });
    }
})
</script>

<style lang="scss">
.releazio-posts-page {
    --f7-page-bg-color: var(--white1);

    .loading-wrapper {
        display: flex;
        justify-content: center;
        margin-top: 32px;
    }

    &__navbar {
        --f7-bars-bg-color: var(--white1);

        .title {
            display: flex;
            flex-direction: column;
            gap: 2px;

            .text {
                @include text(16px, 500, var(--brown1), 19.2px);
            }

            .version {
                @include text(12px, 500, var(--grey2), 14.4px);
                display: inline-flex;
                justify-content: center;
            }

            .icon-arrow-right {
                width: 10px;
                height: 10px;
                background-image: url("data:image/svg+xml,%3Csvg width='10' height='11' viewBox='0 0 10 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.96497 2.70471C5.88368 2.62478 5.7771 2.58463 5.67042 2.58463C5.56374 2.58463 5.45706 2.62478 5.37587 2.70471C5.21292 2.86493 5.21292 3.12381 5.37587 3.28403L7.21414 5.09152L2.15945 5.09152C1.92944 5.09152 1.74278 5.27505 1.74278 5.50121C1.74278 5.72736 1.92944 5.9109 2.15945 5.9109L7.21414 5.9109L5.37578 7.71848C5.21283 7.8787 5.21283 8.13758 5.37578 8.2978C5.53872 8.45803 5.80202 8.45802 5.96497 8.2978L8.51451 5.79096C8.67746 5.63074 8.67746 5.37186 8.51451 5.21164L5.96497 2.70471Z' fill='%23818E95'/%3E%3C/svg%3E%0A");
            }

            .next-version {
                @include text(12px, 700, var(--blue1), 14.4px);
            }
        }
    }

    .page-content {
        &::-webkit-scrollbar {
            width: 0;
        }

        .iframe {
            border: none;
            opacity: 0;
            width: 100%;
            height: calc(100% - 6px);
            will-change: transform, opacity;
            transition-property: transform, opacity;
            transition-duration: 400ms;

            &::-webkit-scrollbar {
                width: 0;
            }
        }
    }
}
</style>